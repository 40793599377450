.custom-table {
  display: grid;
  color: black;
  overflow-x: auto;
  padding-top: 12px;
}

.custom-table .header,
.custom-table .row,
.custom-table .rowGroup {
  display: contents;
}
.custom-table .header-data-parent,
.custom-table .row-data-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.custom-table .header-data-parent {
  position: relative;
  font-size: 6px;
  font-weight: 500;
  align-items: flex-end;
  padding-bottom: 0.5px;
  padding-inline: 2.5px;
}

.custom-table .row-data-parent {
  height: 13px;
  font-size: 6.5px;
  line-height: 11px;
  padding-inline: 2.5px;
  color: #333333;
  font-weight: 400;
  padding-top: 1px;
  padding-bottom: 1px;
}

.custom-table .headingBorder {
  border-bottom: 0.5px solid #d0d0d0;
}

.custom-table .borderBottom {
  border-bottom: 0.5px solid #e9e9e9;
}

.custom-table .total-row {
  border-bottom: 0.5px solid black;
}

.custom-table .header-data {
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-table .row-data {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.custom-table .resize-handle {
  position: absolute;
  right: 0px;
  width: 4px;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: none;
  cursor: col-resize;
  background-color: #dcddde;
}

.custom-table .resize-handle.active {
  display: block;
}