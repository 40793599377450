.trade-rotate {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.tradeHr:last-child hr {
  display: none;
}

.trade-list-collapse hr:last-child{
  display: none;
}

.live-price-modal {
  .comapct-table-skeleton {
    .skeleton-selection-box {
      width: 50px;
    }
    th {
      padding-block: 7px;
    }
    td {
      padding-block: 6px;
    }
  }
}

.Account-trade {
  // padding-left: 280px;
  // padding-right: 43px;
  // padding-top: 10px;
  // padding-bottom: 20px;
  margin-top: 0px !important;
  position: relative;
}

.tardeApprove {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}

.tradeMainhead {
  margin: 0 0 10px;
  position: relative;
  z-index: 96;
  background-color: white;
}

.tradeMainbody {
  position: relative;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: scroll;
}

.tradetable {
  overflow-x: auto;
  overflow-y: scroll;
  height: 80vh;
  position: relative;
}

.tradeApprove-btn {
  position: relative;
  float: right;

  .tardeApprove {
    background-color: #34c38f;
    color: white;
    border: none;
    width: 145px;
    &:hover{
      background-color: #34c38f;
    }
  }
}

.tradeHeader {
  padding: 0 16px 16px 16px;
  position: fixed;

  .secondLeftHead {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #74788d;
  }

  .secondRightHead {
    margin-left: 39%;

    .tradeDownload {
      font-weight: 500;
      font-size: 16px;
      color: #464f5e;
    }

    .tradeSearch {
      width: 274px;
      height: 36px;
      border: 0.5px solid #6e8195;
      border-radius: 50px;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #464f5e;
      padding: 8px 40px 8px 14px;
    }

    .searchIcon {
      margin-left: -36px;
      cursor: pointer;
    }

    .filter-btn {
      margin-left: 26px;
      background: #ffff;
      width: 109px;
      height: 36px;
      border: 0.5px solid #74788d;
      border-radius: 36px;
      font-weight: 500;
      font-size: 16px;
      color: #464f5e;
    }
  }
}

.responsive-trade-header {
      .table-row {
        border-radius: 1px;
        padding: 15px 20px;
        width: 172rem;
        display: flex;
        justify-content: space-between;
        // border-top: 1px solid rgba(116, 120, 141, 0.2);
      }
  
      .table-trade-header {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #464f5e;
      }

  .col {
    color: #74788d;
  }

  .col-1 {
    flex-basis: 1.5%;
  }

  .col-2 {
    flex-basis: 4%;
  }

  .col-3 {
    flex-basis: 4%;
  }

  .col-4 {
    flex-basis: 4%;
  }

  .col-5 {
    flex-basis: 5%;
    margin-left: 20px;
  }

  .col-6 {
    flex-basis: 4%;
  }

  .col-7 {
    flex-basis: 4%;
  }

  .col-8 {
    flex-basis: 4.5%;
  }

  .col-9 {
    flex-basis: 4%;
  }

  .col-10 {
    flex-basis: 4%;
  }

  .col-11 {
    flex-basis: 3%;
  }

  .col-12 {
    flex-basis: 4%;
  }
}

.tradetable {
  .responsive-trade-header {
    top: 0;
    position: sticky;
    z-index: 1;
  }

  .table-trade-header {
    background: #fff;
  }

}

.responsive-trade-table {
  .table-row {
    border-radius: 12px;
    padding: 15px 20px;
    width: 170rem;
    height: 50px;
    display: flex;
    justify-content: space-between;
  }

  .table-trade-row {
    background-color: #ffffff;
    border: 1px solid #74788D;
    border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #34475a;
    font-size: 14px;
    width: 172rem !important;
    white-space: nowrap;
    font-family: "Open Sans";
  }

  .table-trade-row-yellow {
    background-color: #ffffff;
    border: 3px solid #EFAE3A;
    border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #34475a;
    font-size: 14px;
    width: 172rem !important;
    white-space: nowrap;
    font-family: "Open Sans";
  }

  .table-trade-row-red {
    background-color: #ffffff;
    border: 3px solid #F05F5F;
    border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #34475a;
    font-size: 14px;
    width: 172rem !important;
    white-space: nowrap;
    font-family: "Open Sans";
  }

  .table-trade-row-green {
    background-color: #ffffff;
    border: 3px solid #34C38F;
    border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #34475a;
    font-size: 14px;
    width: 172rem !important;
    white-space: nowrap;
    font-family: "Open Sans";
  }

  .table-trade-row:hover {
    box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .col {
    color: #74788d;
  }

  .col-1 {
    flex-basis: 1.5%;
  }

  .col-2 {
    flex-basis: 4%;
  }

  .col-3 {
    flex-basis: 4%;
  }

  .col-4 {
    flex-basis: 4%;
  }

  .col-5 {
    flex-basis: 5%;
    margin-left: 20px;
  }

  .col-6 {
    flex-basis: 4%;
    padding-left: 20px;
  }

  .col-7 {
    flex-basis: 4%;
    padding-left: 20px;
  }

  .col-8 {
    flex-basis: 4.5%;
  }

  .col-9 {
    flex-basis: 4%;
  }

  .col-10 {
    flex-basis: 4%;
  }

  .col-11 {
    flex-basis: 3%;
  }

  .col-12 {
    flex-basis: 4%;
  }

  .row ul {
    margin: 0;
    padding: 0;
  }
}

.grid-table-trade-row-green {
  border-left: 3px solid #34C38F;
  border-right: 3px solid #34C38F;

  &:has(.cell-group) {
    border: 3px solid #34C38F;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  &:has(.cell-group-open) {
    border-top: 3px solid #34C38F;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:has(.cell-leaf)+&:not(:has(.cell-group-open)) {
    border-bottom: 3px solid #34C38F;
    margin-bottom: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.grid-table-trade-row-yellow {
  border-left: 3px solid #EFAE3A;
  border-right: 3px solid #EFAE3A;

  &:has(.cell-group) {
    border: 3px solid #EFAE3A;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  &:has(.cell-group-open) {
    border-top: 3px solid #EFAE3A;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:has(.cell-leaf)+&:not(:has(.cell-group-open)) {
    border-bottom: 3px solid #EFAE3A;
    margin-bottom: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.grid-table-trade-row-red{
  border-left: 3px solid #F05F5F;
  border-right: 3px solid #F05F5F;
  &:has(.cell-group) {
      border: 3px solid #F05F5F;
      border-radius: 10px;
      margin-bottom: 10px;
    }
  &:has(.cell-group-open){
    border-top: 3px solid #F05F5F;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
    &:has(.cell-leaf)+&:not(:has(.cell-group-open)) {
      border-bottom: 3px solid #F05F5F;
        margin-bottom: 10px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
    }
}

.grid-table-trade-row {
  border-left: 1px solid #74788D;
  border-right: 1px solid #74788D;

  &:has(.cell-group) {
    border: 1px solid #74788D;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  &:has(.cell-group-open) {
    border-top: 1px solid #74788D;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:has(.cell-leaf)+&:not(:has(.cell-group-open)) {
    border-bottom: 1px solid #74788D;
    margin-bottom: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.tooltip-inner {
  background: white;
  border: 1px solid gray;
  margin-top: 98%;
  padding: 5px;
}

.tooltip-trade {
  display: flex;
  position: relative;

  .tooltiptext {
    font-size: 12px;
    margin-top: 5px;
    visibility: hidden;
    width: 95px;
    background-color: rgba(23, 92, 194, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    text-transform: capitalize;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 99999;
    top: 100%;
    transform: translateX(-20%);
    
  }
}

.tooltip-trade:hover {
  .tooltiptext {
    margin-left: -10px;
    visibility: visible;
    text-transform: capitalize;
  }
}

@media screen and (min-width:1400px) {}

@media screen and (max-width:600px) {
  .Account-trade {
    // padding-left: 20px;
    // padding-right: 20px;
  }
}

@media screen and (min-width:601px) and (max-width:1400px) {
  .Account-trade {
    // padding-left: 80px;
    // padding-right: 40px;
  }
}

@media screen and (min-width:601px) and (max-width:800px) {}

// @media screen and (min-width:1720px) {
  body[data-legacy-view="true"] {
    max-width: 100%;
    // .Account-trade {
    //     padding-left: 80px;
    //     padding-right: 40px;
    //   }
  }
// }

.compact-table {
  td, th {
    padding: 4px;
    font-size: 12px;
  }
}

.react-grid-layout-card {
  .drag-handle {
      display: none;
      position: sticky;
      float: right;
      right: 8px;
      top: 8px;
      z-index: 99;
      cursor: pointer;
    }
    &:hover {
      .drag-handle{
        display: flex;
      }
    }
    .react-resizable-handle{
      background: none;
      &::after{
            right: 8px;
              bottom: 8px;
              width: 15px;
              height: 15px;
              border-right: 3px solid rgba(0, 0, 0, 0.4);
              border-bottom: 3px solid rgba(0, 0, 0, 0.4);
      }
    }
}

.trade-comment-area {
  font-size: 16px;

  .comment-text {
    overflow: auto;
    padding: 0 16px 0 32px;
    overflow-wrap: break-word;

    ol,
    ul {
      padding-left: 1rem !important;
    }
  }
}

.trade-message-area {
  .rsw-btn {
    border-radius: 5px;
  }

  .rsw-ce {
    padding-left: 14px;
  }

  .rsw-editor {
    min-height: 150px;
  }

  ol,
  ul {
    padding-left: 1rem !important;
  }
}