.headr_2 li {
  list-style: none;
}
.headr_2 {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headr_2_main_div {
  border-top: 1px #74788d solid;
  padding: 3px 0.9rem;
}
.part_2 {
  font-size: 7px;
  /* color: #000; */
}
.part_3 {
  font-size: 7px;
  color: var(--primaryColor);
  font-family: 'Open Sans', sans-serif;
}
