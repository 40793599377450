.tax-management-comparison-chart {
  font-family: 'Open Sans', sans-serif;
}
.light-box {
  border-radius: 2px;
  background: #e6ecf3;
  text-align: center;
  padding: 5px;
}
.light-box .header {
  color: var(--headerColor);
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
}

.light-box p {
  color: var(--headerColor);
  text-align: center;
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
}
.bg-green {
  background: #3bbfa3;
}
.bg-green p {
  color: #ffffff !important;
}
