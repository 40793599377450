/* li {
  list-style: none;
}
ul,
p {
  margin: 0;
  padding: 0;
} */
.table_td,
.table_footer {
  margin: 0;
  padding: 0;
}
.table_td p,
.table_footer p {
  margin: 0;
  padding: 0;
}
.main_div {
  height: auto;
  width: 100%;
  color: var(--headerColor);
  font-family: 'Open Sans', sans-serif;
  /*background-color: #847;*/
  /*border-radius: 5px;*/
}

.table_title {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 8px 0;
  margin: 0;
  background-color: #3369a6;
}

.table_containt {
  font-size: 8px;
  padding: 10px 10px 5px 10px;
  border-bottom: 0.5px #b9bbc5 solid;
  /* color: var(--headerColor); */
  font-weight: 600;
}

.table_footer_containt {
  font-size: 10px;
  /* color: var(--headerColor); */

  font-weight: 600;
  border-bottom: 0.5px #d6d7dd solid;
  background-color: #e6ecf3;
  padding: 10px 10px 5px 10px;
}
.table_containt_color {
  background-color: #f3f5f9;
}
