.AccountDetailspage {
    // padding-left: 280px;
    // padding-right: 43px;
    // padding-top: 70px;
    // padding-bottom: 20px;
    position: relative;
}

// .sync-icon-rotate.rotate {
//     animation: rotate 2s ease-in 0s infinite;
// }

@keyframes rotate {
    100% {
        transform: rotate(-360deg);
    }
}

.blotter-sticky .table-responsive::-webkit-scrollbar {
    display: none;
  }
 
  .blotter-sticky .table-responsive {
    overflow-y: scroll;
    // height: calc(100vh - 164px);
}
.blotter-sticky .table-responsive table thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}
.blotter-sticky .table-responsive table thead tr {
    background: #fff;
    box-shadow: 0 0.2px rgba(116,120,141,.3);
}

.add-btn-data>button {
    background: #175CC2;
    border-radius: 36px;
    color: white;
    border: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
}

.form-control,
.form-select {
    box-shadow: none !important;
    border: 1px solid #ced4da;
    border-radius: 5px !important;
    font-size: 16px;
    // color: #94A3B8; 
    padding-top: 8px;
    padding-bottom: 8px;

    &::placeholder {
        color: #94A3B8;
    }

    &.invalid {
        background: rgba(240, 95, 95, 0.1);
        border-color: #F05F5F;
        ~.invalid-feedback {
            display: block;
        }
    }

    &.invalid-form {
        background: rgba(240, 95, 95, 0.1);
        border-color: #F05F5F;

        ~.invalid-feedback {
            display: block;
            color: #F05F5F;
            font-size: x-small;
        }
    }
}
.react-datepicker__input-container{
    input{
      width: 100%;
      height: 56px;
      padding: 0.375rem 2.25rem 0.375rem 0.75rem;
      -moz-padding-start: calc(0.75rem - 3px);
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #7f8a99;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none;
  }
}
.react-datepicker-wrapper,
.rbt {
    &.invalid {
        .form-control {
            background: rgba(240, 95, 95, 0.1);
            border-color: #F05F5F;
        }

        ~.invalid-feedback {
            display: block;
        }
    }
}

.modal-width {
    width: 600px;
}
.modal-ext-width{
    width: 500px;
}
.restsubs-modal{
    width: 1500px;
}
.nodatamsg {
    // display: inline;
    width: fit-content;
}

.edit-table {
    margin-top: 40px;
    margin-bottom: 76px;

    th {
        display: inline-block;
        width: 50%;
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 43px;
        font-size: 14px;
        line-height: 20px;

    }

    @media only screen and (max-width: 950px) {
        #accId {
            min-height: 57px;
        }
    }

    @media only screen and (max-width: 380px) {
        #accId {
            min-height: 77px;
        }
    }

    td {
        display: inline-block;
        width: 50%;
        // padding-top: 10px;
        // padding-bottom: 10px;
        min-height: 43px;
        font-size: 14px;
        line-height: 20px;

        .inputEdit {
            // border: none;
            padding: 0px 2px;
            box-shadow: none;
            border: 1px solid;
            border-radius: 2px;
            border-color: #707070;
        }

        .inputEdit:focus {
            outline: -webkit-focus-ring-color auto 1px;
        }
    }

}

.confirm-btn {
    margin-bottom: 10px;
}

.confirm-btn-restsub {
    margin-top: 30px;
    display: table-caption;
}

.delete-btn {
    background-color: transparent;
    border: none;
}

.edit-btn {
    background-color: transparent;
    border: none;
}
@media screen and (min-width:1400px) {}
@media screen and (max-width:600px) {
    .AccountDetailspage {
        // padding-left: 20px;
        // padding-right: 20px;
    }
}
@media screen and (min-width:601px) and (max-width:1400px) {
    .AccountDetailspage {
        // padding-left: 80px;
        // padding-right: 40px;
    }
}
@media screen and (min-width:601px) and (max-width:800px) {}


// trade-history css
.history-action{
    padding-left: 0px !important;
}