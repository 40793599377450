  th {
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 21px;
    color: #74788D !important;
  }

.actions-select {
  width: 200px;
}

.factor-tilt-table-header {
  font-family: 'Open Sans';
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 18px;
  color: #74788D;
}

.submitBox img {
  margin-top: 40px;
}

.submitBox h4 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 26px;
  line-height: 34px;
}

.blur-background-parent {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .1);
  backdrop-filter: blur(2px);
}

.oboardSuccess {
  padding: 2%;
}

.apimsgbox {
  margin-top: 3%;
}

.collaspe-margin {
  margin-top: 3%;
}

.apimsg {
  .msglist {
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: -10px;
  }
}

.apimsg {
  .msglist2 {
    display: flex;
    margin-left: 14%;
    margin-right: 5%;
    margin-bottom: -10px;
  }
}

.apimsg {
  .msglist:last-child {
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: -15px;
  }
}

.security-holdings-table .invalid-feedback {
  height: 0;
  margin-top: 0px;
  margin-bottom: 2px;
}

.confirm-btn {
  margin-top: 10%;
  height: 36px;
  border-radius: 36px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 40px;
}

.model-card {
  background: rgba(221, 221, 221, 0.06);
  border: 1px solid rgba(116, 120, 141, 0.3);
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2) !important;
  border-radius: 10px !important;
}

.modelCardSub {
  font-weight: 400 !important;
  line-height: 21px !important;
  color: #34475A;
  font-size: 14px !important;
}

.sectorpersonalization .MuiTablePagination-toolbar {
  align-items: baseline;
}

input::-webkit-file-upload-button:hover {
  cursor: pointer;
}