.icon-top {
    width: 40px;
    height: 40px;
    background: rgba(116, 120, 141, 0.1);
    border-radius: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.collapse-btn {
    width: 25px;
    height: 25px;
    background: transparent;
    border-radius: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    img {
        filter: invert(1);
    }

    .rotate-0 {
        transform: rotate(0deg);
    }

    .rotate-90degn {
        transform: rotate(-90deg);
    }
}

.account-dashboard {
   // padding-left: 280px;
    // padding-right: 43px;
    // padding-top: 10px;
    // padding-bottom: 20px;
    // position: relative;

    .tooltip-statWidget {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 21ch;

        .toolTipText {
            font-size: 12px;
            margin-top: 20px;
            visibility: hidden;
            width: auto;
            background-color: rgba(23, 92, 194, 0.8);
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px;
            text-transform: capitalize;
            /* Position the tooltip */
            position: absolute;
            z-index: 99999;
        }
    }

    .tooltip-statWidget:hover {
        .toolTipText {
            visibility: visible;
            text-transform: capitalize;
        }
    }

    .feature-card {
        justify-content: flex-start;
        padding: 18px 20px;

        .text-title {
            font-size: 14px;
            line-height: normal;
        }

        .stats {
            font-size: 17px;
            line-height: normal;
        }

        .icon {
            width: 50px;
            height: 50px;
            background: #34475A26;
            border-radius: 25px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            order: 1;

            img {
                max-height: 30px;
                max-width: 30px;
            }
        }

        .desc {
            order: 2;
            margin-left: 20px;
        }

        h1 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 21ch;
        }

        .tooltip-statWidget {
            display: flex;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 21ch;

            // text-overflow: ellipsis,
            // overflow: hidden;
            .toolTipText {
                font-size: 12px;
                margin-top: 10px;
                visibility: hidden;
                width: 80px;
                background-color: rgba(23, 92, 194, 0.8);
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 5px;
                text-transform: capitalize;

                /* Position the tooltip */
                position: absolute;
                z-index: 99999;
                top: 100%;
                transform: translateX(-20%);
            }
        }

        .tooltip-statWidget:hover {
            .toolTipText {
                visibility: visible;
                text-transform: capitalize;
            }
        }
    }

    .request-progress {
        height: 9px;
        width: 70px;
        margin-left: 5px;

        .progress-bar {
            border-radius: 0.375rem;
        }
    }

    .request-list {
        margin-top: 10px;
        max-height: 210px;
        overflow-y: auto;
        overflow-x: hidden;

        .dashboard-comment {
            background: rgba(221, 221, 221, 0.06);
            border: 1px solid rgba(116, 120, 141, 0.3);
            border-radius: 10px;
            padding: 10px;

            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #34475A;
            }

            .time {
                font-weight: 400;
                font-size: 10px;
                line-height: 13px;
                /* identical to box height */
                color: #74788D;
                margin-bottom: 0;
                line-height: normal;
            }

            .text-title {
                font-size: 12px;
                line-height: normal;
            }
        }
    }

    .redirectarrow {
        background-color: transparent;
        width: 30px;
        color: blue;
        border: none;
        font-weight: 300;
        background-color: #8BAEE1;
        color: white;
        border: none;
        // width: 145px;
        // height: 36px;
        border-radius: 36px;
    }

    .request-list::-webkit-scrollbar-track {
        // -webkit-box: inset 0 0 6px rgba(0,0,0,0.3);
        margin-left: 2px;
        border-radius: 10px;
        background: transparent;
    }

    .request-list::-webkit-scrollbar {
        margin-left: 2px;
        width: 12px;
        background: transparent;
    }

    .request-list::-webkit-scrollbar-thumb {
        margin-left: 2px;
        background: rgba(116, 120, 141, 0.2);
        border-radius: 5px;
        transform: rotate(90deg);
    }

    .current-legend,
    .target-legend {
        display: inline-flex;
        align-items: center;

        span {
            width: 11px;
            height: 11px;
            background: rgba(116, 120, 141, 0.3);
            border-radius: 3px;
            margin-right: 8px;
            margin-left: 10px;
        }

        .text-title {
            font-size: 14px !important;
        }
    }

    .target-legend {
        span {
            background: linear-gradient(0deg, rgba(240, 95, 95, 1) 50%, rgba(22, 157, 108, 1) 100%) !important;
        }
    }

    canvas {
        width: 100% !important;
        height: auto !important;
    }
}

@media screen and (min-width:1400px) {}

@media screen and (max-width:600px) {
    .account-dashboard {
        // padding-left: 20px;
        // padding-right: 20px;
    }
}

@media screen and (min-width:601px) and (max-width:1400px) {
    .account-dashboard {
        // padding-left: 80px;
        // padding-right: 40px;
    }
}

@media screen and (min-width:601px) and (max-width:800px) {}