.acl-loader td,.acl-loader th {
	padding: 8px 10px !important;
}

.acl-loader .action-loader{
	padding: 6px 10px !important;
}

.acl-loader.table-responsive table tr td:first-child,.acl-loader.table-responsive table tr th:first-child {
    padding-left: 14px;
}

.input-spn input[type=color]{
	width: 150px;
	height: 37px;
	border: none;
	background: none;
}

.input-spn input[type="color"]::-webkit-color-swatch-wrapper {
    border: none;
    padding: 0;
    margin: 0;
}

.input-spn input[type="color"]::-webkit-color-swatch {
	border: none; 
	border-radius: 5px;
}
