.sidebar_root {
  width: 100%;
  max-width: 243px;
  padding: 0;
  transition: all var(--transition);
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar_root_mini {
  max-width: 0px;
  width: 100%;
  padding: 0px;

  transition: all var(--transition);
}

.back-arrow {
  background-color: var(--white);
  box-shadow: 0px 1.3px 13px rgb(0 0 0 / 5%);
  border-radius: 5.525px;
  position: absolute;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  transform: translate(0%, 50%);
  cursor: pointer;
  z-index: 2;
}
.back-arrow.open {
  transition: all var(--transition);
  right: 10px;
}
.back-arrow.close {
  transition: all var(--transition);
  right: -50px;
}
img.open-arrow {
  transform: rotate(180deg);
}
.sidebar-right {
  width: 225px;
  height: 100%;
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: width 0.3s ease;
  overflow-y: auto;
}

.sidebar-right .user-account {
  display: flex;
  align-items: center;
  padding: 20px 10px 20px 10px;
  color: var(--black);
  width: 100%;
  display: none;
}
.user-account img {
  height: 45px;
  border-radius: 50%;
}

.user-account h2 {
  font-size: 1.2rem;
  font-weight: 600;
  display: block;
  margin-left: 15px;
}

.links {
  list-style: none;
  margin-top: 10px;
  overflow-y: auto;
  height: calc(100% - 140px);
  padding: 0;
  width: 100%;
}
.links h4 {
  font-size: 16px !important;
  padding: 0 10px;
  margin: 0;
}
.links li {
  display: flex;
  border-radius: 4px;
  align-items: center;
  transition: 0.3s ease;
}

.links span {
  padding: 12px 10px;
}
.links li .name {
  display: block;
  width: 100%;
  color: var(--black);
  font-weight: 500;
  text-decoration: none;
  padding: 10px 20px;
  white-space: nowrap;
}

.links li:hover {
  background: var(--white);
}

.logout {
  margin-top: 20px;
}

.links::-webkit-scrollbar {
  display: none;
}

.sidebar-right:hover h4 {
  display: block;
}

.sidebar-right:hover hr {
  border-color: transparent;
}

.sidebar_root .sidebar-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/*====================*/
.color-picker-lable {
  color: #959595;
  padding: 2px 00px 2px 10px;
  vertical-align: super;
}
.color-picker-input {
  border: 1px solid #c4c4c4;
  height: 20px;
  width: 20px;
  padding: 0;
  margin: 0;
  border-radius: 5px;
}
.color-picker-input::-webkit-color-swatch-wrapper {
  border: none;
  padding: 0;
  margin: 0;
}
.color-picker-input::-webkit-color-swatch {
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 5px;
}
.color-picker {
  width: 100%;
}
.check-box-spacing {
  margin-top: 10px;
}
.color_lable {
  padding: 0 15px 0 0;
  color: #959595;
  width: 70px;
}
.number-input{
  border: 1px solid #c4c4c4;
  width: 55px;
  padding: 3px;
  margin: 0;
  color: #000;
  border-radius: 5px;
}
.font-select-box {
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  background-position: right 0rem center !important;
  background-size: 10px 12px !important;
  width: 50% !important;
  color: #000 !important;
  cursor: pointer;
}
.font-select-box.border-b {
  border-bottom: 1px var(--primaryColor) solid !important;
}
.font-select-box:focus {
  box-shadow: unset !important;
}
.font-select-box:focus-visible {
  box-shadow: unset !important;
  border: unset !important;
  outline: unset !important;
}
.color_dropdown_input {
  height: 18px;
  width: 18px;
}
.right_side_title {
  font-size: 16px !important;
}
.text_heading_lable {
  color: #959595;
  font-size: 14px;
}
/*====================*/
/*==================================== checkBox =======================================*/
.cl-checkbox {
  position: relative;
  display: flex;
  margin: 2px 0;
  cursor: pointer;
}

/* Input */
.cl-checkbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.cl-checkbox > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  color: #959595;
}

/* Box */
.cl-checkbox > .box-main {
  height: 100%;
  position: relative;
}
.cl-checkbox > .box-main > div::before {
  content: '';
  display: inline-block;
  box-sizing: border-box;
  border: solid 2px;
  margin: 0;
  border-color: #bebebe;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.cl-checkbox > .box-main > div::after {
  content: '';
  display: block;

  position: absolute;
  top: 51%;
  left: 50%;
  width: 10px;
  height: 5px;
  margin: 0;
  padding: 0;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.checkbox-padding {
  padding-left: 5px;
}
/* Checked, Indeterminate */
.cl-checkbox > input:checked,
.cl-checkbox > input:indeterminate {
  /*background-color: #018786;*/
}

.cl-checkbox > input:checked + .box-main > div::before,
.cl-checkbox > input:indeterminate + .box-main > div::before {
  border-color: #bebebe;
  background-color: transparent;
}

.cl-checkbox > input:checked + .box-main > div::after,
.cl-checkbox > input:indeterminate + .box-main > div::after {
  border-color: #bebebe;
}

.cl-checkbox > input:indeterminate + .box-main > div::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover, Focus */
.cl-checkbox:hover > input {
  opacity: 0.04;
}

.cl-checkbox > input:focus {
  opacity: 0.12;
}

.cl-checkbox:hover > input:focus {
  opacity: 0.16;
}

/* Active */
.cl-checkbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.cl-checkbox > input:active + span::before {
  border-color: #85b8b7;
}

.cl-checkbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Disabled */
.cl-checkbox > input:disabled {
  opacity: 0;
}

.cl-checkbox > input:disabled + span {
  color: rgba(0, 0, 0, 0.38);
  cursor: initial;
}

.cl-checkbox > input:disabled + span::before {
  border-color: currentColor;
}

.cl-checkbox > input:checked:disabled + span::before,
.cl-checkbox > input:indeterminate:disabled + span::before {
  border-color: #fff;
  background-color: currentColor;
}
/* ============================ */
