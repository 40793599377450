#main-content.expanded {
    padding-right: 50px !important;
}

#chart-container-expanded {
    height: calc(100vh - 135px);
    width: 100%;
    position: relative;
    border-top: 2px solid #e0e0e0;
}

.filter-data-list {
    max-height: calc(100vh - 200px);
    overflow: auto;
    padding-left: 4px;
}

#market-chart-expanded {
    position: relative;
}

#market-chart-expanded .apexcharts-point-annotation-marker {
    display: none;
}

#market-chart-expanded, .apexcharts-xaxistooltip {
    white-space: nowrap;
}

#market-chart-expanded .apexcharts-point-annotations .apexcharts-point-annotation-marker~rect {
    cursor: pointer !important;
}

#market-chart-expanded .red-border {
    background: #fff;
    border-left: 4px solid #d32f2f;
    border-radius: 4px;
    box-shadow: 0 2px 4px #787b8666;
    box-sizing: border-box;
    color: #131722;
    padding: 12px 12px;
    width: 250px;
    z-index: 15;
    font-size: 12px;
}

#market-chart-expanded .green-border {
    background: #fff;
    border-left: 4px solid #33A68D;
    border-radius: 4px;
    box-shadow: 0 2px 4px #787b8666;
    box-sizing: border-box;
    color: #131722;
    padding: 12px 12px;
    width: 250px;
    z-index: 15;
    font-size: 12px;
}

#market-chart-expanded .blue-border {
    background: #fff;
    border-left: 4px solid #002A59;
    border-radius: 4px;
    box-shadow: 0 2px 4px #787b8666;
    box-sizing: border-box;
    color: #131722;
    padding: 2px 8px;
    width: 250px;
    z-index: 15;
    font-size: 12px;
}

.view-more-btn {
    color: #2962ff;
    width: 90px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 140px;
    margin-bottom: 8px;
    background-color: transparent;
    border: 0.5px solid #2962ff;
}


#market-chart-expanded .green-border #heading,
#market-chart-expanded .red-border #heading,
#market-chart-expanded .blue-border #heading {
    margin: 0;
    margin-bottom: 12px;
}

#market-chart-expanded .green-border b,
#market-chart-expanded .blue-border b,
#market-chart-expanded .red-border b {
    font-weight: normal;
}

#market-chart-expanded .green-border #data-row b:last-child,
#market-chart-expanded .red-border #data-row b:last-child,
#market-chart-expanded .blue-border #data-row b:last-child,
#market-chart-expanded .green-border #date-row b:last-child,
#market-chart-expanded .blue-border #date-row b:last-child,
#market-chart-expanded .red-border #date-row b:last-child {
    font-weight: 600;
}

#market-chart-expanded .green-border #data-row p:first-child,
#market-chart-expanded .red-border #data-row p:first-child,
#market-chart-expanded .green-border #date-row p:first-child,
#market-chart-expanded .red-border #date-row p:first-child {
    margin: 0 12px 0px 0px;
    width: 80px !important;
    display: inline-block;
}


#chart-container-expanded .apexcharts-reset-icon {
    margin-left: 0;
}

#chart-container-expanded .apexcharts-toolbar {
    gap: 8px;
    padding-top: 4px;
}

#chart-container-expanded .apexcharts-toolbar .apexcharts-pan-icon,
#chart-container-expanded .apexcharts-toolbar .apexcharts-zoom-icon,
#chart-container-expanded .apexcharts-toolbar .apexcharts-reset-icon,
#chart-container-expanded .apexcharts-toolbar .apexcharts-menu-icon {
    transform: none;
}

#chart-container-expanded .apexcharts-toolbar img {
    height: 100%;
    width: 100%;
}