// Default variable overrides
$cyan: #50CAF1;
$grid-breakpoints: (
        xs: 0,
        sm: 601px,
        md: 800px,
        lg: 1024px,
        xl: 1400px,
        xxl: 1720px
);
$spacer: 0.5rem;
$spacers: (
        0: 0,
        1: $spacer * .5,
        2: $spacer,
        3: $spacer * 1.5,
        4: $spacer * 2,
        5: $spacer * 3,
        6: $spacer * 4,
);
$card-spacer-y: $spacer * 3;
$card-spacer-x: $spacer * 3;
$card-border-color: #74788d4c;
$card-border-radius: 10px;
$card-box-shadow: 0px 4px 4px rgba(116, 120, 141, 0.5);
// @import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import './index.scss';

::-webkit-scrollbar-track {
        -webkit-box: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background: transparent;
}

::-webkit-scrollbar {
        width: 12px;
        height: 8px;
        background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
        background: rgba(116, 120, 141, 0.2);
        height: 10px;
        border-radius: 5px;
        transform: rotate(90deg);
}