.initial-portfolio-box {
  background-color: #e6ecf3;
  padding: 5px 5px 5% 5px;
}

.portfolio-heading {
  font-family: 'Open Sans';
}
.portfolio-info-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section-heading {
  font-family: 'Open Sans';
}

.value-font {
  font-family: 'Open Sans';
  text-align: left;
}
.chart-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chart-section {
  flex: 1;
  padding: 5px;
  font-family: 'Open Sans', sans-serif;
  color: #002a59;
}
/* .chart-container {
  flex: 2;
  height: 150px;
} */
.bottom-value-box,
.percentage-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
  margin-right: 10px;
  border-radius: 3px;
}

.bottom-value-box {
  background-color: #002a59;
  color: #fff;
}
.percentage-box {
  background-color: #bed9ff;
  color: #202a34;
}

.bottom-box {
  display: flex;
  padding: 15px;
  margin-top: -8%;
}