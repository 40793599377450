.table-container-primary {
  border-collapse: collapse;
  width: 100%;
  border: 0;
  font-family: 'Open Sans', sans-serif;
  /* padding-inline: 2.5px; */
}

.table-container-primary .text-header {
  font-size: 6px !important;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0.5px;
  vertical-align: bottom;
  color: black !important;
  padding: 0;
  padding-inline: 2.5px;
}
.table-container-primary .table-border table thead {
  /* padding: 5px; */
}

.table-container-primary .table-body tr {
}

.table-container-primary td {
  /* padding: 0; */
  padding-inline: 2.5px;
  color: #333333;
  font-size: 6.5px;
  line-height: 11px;
  font-weight: 400;
  padding-top: 1px;
  padding-bottom: 1px;
}
.table-container-primary .table-border {
  padding-top: 12px;
}

.table-container-primary .borderBottom {
  border-bottom: 0.5px solid #e9e9e9;
}

.table-container-primary .headingBorder {
  border-bottom: 0.5px solid #d0d0d0;
}

.risk-color {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.risk-color div {
  width: 15px;
  height: 2px;
  border-radius: 100px;
}
.risk-color .g {
  background: #a4bf7f;
  margin-right: 8px;
}
.risk-color .r {
  background: #f67979;
  margin-right: 8px;
}
.risk-color .n {
  background: #c7c7c7;
  margin-right: 8px;
}
.risk-color .y {
  background: rgb(192, 182, 49);
  margin-right: 8px;
}

.risk-color div:last-child {
  margin-right: 0px;
}
