.risk-page {
    // padding-left: 280px;
    // padding-right: 43px;
    // padding-top: 10px;
    // padding-bottom: 20px;
    position: relative;
}

.feature-card {
    background: rgba(23, 92, 194, 0.08);
    box-shadow: 0px 4px 4px rgba(116, 120, 141, 0.5);
    border-radius: 10px;
    padding: 18px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
        img {
            height: 30px;
            width: 30px;
        }
    }

    .stats {
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        color: #34475A;
    }
}

.slider-card {
    padding: 18px 30px;
    z-index: 0;
}

.risk-page {
    .rc-slider {
        height: 50px !important;
        margin-top: 62px;
        margin-bottom: 29px;

        .rc-slider-step {
            height: 32px !important;
            border-radius: 10px !important;
            background: linear-gradient(90deg, rgba(240, 95, 95, 0.95) 0%, rgba(239, 174, 58, 0.95) 25%, rgba(52, 195, 143, 0.95) 50%, rgba(239, 174, 58, 0.95) 75%, rgba(240, 95, 95, 0.95) 100%) !important;
        }

        .rc-slider-mark {
            top: 50px !important;

            .rc-slider-mark-text {
                font-weight: 500 !important;
                font-size: 18px !important;
                text-align: center !important;
                color: #74788D !important;
            }
        }

        .rc-slider-handle {
            width: 16px;
            height: 50px;
            top: 0;
            background-image: url('../images/Rectangle\ 154.png');
            background-repeat: no-repeat;
            border: none;
            box-shadow: none;
            border-radius: 5px;

            &:focus {
                width: 16px;
                height: 50px;
                top: 0;
                background-image: url('../images/Rectangle\ 154.png');
                background-repeat: no-repeat;
                border: none;
                box-shadow: none;
                border-radius: 5px;
            }

            &:focus-visible {
                width: 16px;
                height: 50px;
                top: 0;
                background-image: url('../images/Rectangle\ 154.png');
                background-repeat: no-repeat;
                border: none;
                box-shadow: none;
                border-radius: 5px;
            }

            &:active {
                width: 16px;
                height: 50px;
                top: 0;
                background-image: url('../images/Rectangle\ 154.png');
                background-repeat: no-repeat;
                border: none;
                box-shadow: none;
                border-radius: 5px;
            }
        }

        .rc-slider-handle.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
            width: 16px;
            height: 50px;
            top: 0;
            background-image: url('../images/Rectangle\ 154.png');
            background-repeat: no-repeat;
            border: none;
            box-shadow: none;
            border-radius: 5px;
        }

        &.rc-slider-disabled {
            background-color: transparent;
        }
    }
}

.hm_hyperModalContentWrapper {
    .hm_hyperCloseIconWrapper {
        width: 25px !important;
        height: 25px !important;
    }
}

.hm_hyperModalWrapper {
    z-index: 999;
}

.relVol-chart {
    .chat-icon {
        color: #002A59;
        position: relative;
        height: 30px;
        width: 60px;
        font-size: 1.4rem;
    }

    .balloon {
        display: flex;
        position: absolute;
        justify-content: center;
        border: 2px solid #97A6E0;
        align-items: center;
        border-radius: 6px;
        height: 36px;
        width: 36px;
        z-index: 2;
        background-color: #97A6E0;
        color: white;
        font-size: 12px;
    }

    .stick {
        width: 20px;
        position: absolute;
        height: 11px;
        margin-left: 12px;
        transform: rotate(45deg);
        top: 26px;
        border-radius: 1px;
        background-color: #97A6E0;
    }
}

@media screen and (min-width:1400px) {
    .hm_hyperModalContentWrapper {
        margin-left: 280px;
        margin-top: 70px;
    }
}

@media screen and (max-width:600px) {
    .risk-page {
        // padding-left: 20px;
        // padding-right: 20px;
    }

    .slider-card {
        padding: 18px 30px;
    }

    .feature-card {
        .icon {
            display: none;
        }

        padding: 15px;

        .text-title {
            font-size: 14px;
            line-height: 18px;
        }

        .stats {
            font-size: 24px;
            line-height: 28px;
        }
    }
}

@media screen and (min-width:601px) and (max-width:1400px) {
    .risk-page {
        // padding-left: 80px;
        // padding-right: 40px;
    }

    .slider-card {
        padding: 18px 30px;
    }
}

@media screen and (min-width:601px) and (max-width:800px) {
    .slider-card {
        padding: 18px 30px;
    }

    .feature-card {
        padding: 18px 20px;

        .icon {
            img {
                height: 24px;
                width: 24px;
            }
        }
    }
}