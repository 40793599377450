.Account-request {
    // padding-left: 280px;
   //  padding-right: 43px;
   //  padding-top: 10px;
   //  padding-bottom: 20px;
}

.account-request-label {
   color: #74788d !important;
   font-size: 16px !important;
   font-weight: 600 !important;
   font-family: 'Open Sans' !important;
}

.all-req-sticky-header{
   padding-bottom: 20px;
   padding-top: 0px;
   z-index: 1;
   background-color: #FFFFFF;
}

.all-req-sticky-table-header{
   top: 60px;
   position: sticky;
   z-index: 1;
   background-color: #FFFFFF;
}

.request-status {
    font-weight: 400;
    font-size: 15px;
    line-height: 13px;
    text-align: center;
    color: #74788D;
    background: rgba(116, 120, 141, 0.15);
    border-radius: 5px;
    height: 20px;
    width: 100px;
}

.filter-btn {
   margin-left: 26px;
   background: #ffff;
   width: 109px;
   height: 36px;
   border: 0.5px solid #74788d;
   border-radius: 36px;
   font-weight: 600;
   font-size: 16px;
   color: #464f5e;
 }
.request-btn {
    position: relative;
   //  padding-top: 40px;
    //  padding-right: 43px;
    float: right;
}

.submit_btn {
    background-color: #175CC2;
    color: white;
    border: none;
    width: 145px;
    height: 36px;
    border-radius: 36px;

}

.req-layout{
       position: relative;
       overflow: hidden;
}

.Pagnate{
   padding-left: 280px;
   padding-right: 43px;
   padding-top: 10px;
   // padding-bottom: 20px;
   width: 100%;
}
.request-btn>button {
    background-color: #175CC2;
    color: white;
    border: none;
    width: 145px;
    height: 36px;
    border-radius: 36px;
}

.allrequesttitle {
    width: 115px;
    height: 26px;
    left: 280px;
    top: 168px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #74788D;
}

// .Account-request{

// }
.modal-4w {
   //  width: 415px;
// height: 822px;
// left: 699px;
// top: 194px;;
    background: #FFFFFF;
    border: 1px solid rgba(116, 120, 141, 0.3);
    box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.accountrequesttable {
    border: 2px solid #175CC2;
    padding: 10px;
}

.action-btn {
    border: none;
    border-radius: 50%;
}

.grid-btnshow {
    border: none;
    border-radius: 50%;
}

.grid-btnhide {
    display: none;
}

.action-clock-btn {
    border: none;
    background-color: transparent;
}

.disabled-action-btn{
   opacity: 0.4;
   cursor: not-allowed !important;
}

@media screen and (max-width:600px) {
    .Account-request {
       //  padding-left: 20px;
       //  padding-right: 20px;
    }

}

@media screen and (max-width:800px) {
    .Account-request {
        padding-left: 80px;
        padding-right: 40px;
    }

}

@media screen and (min-width:1024px) and (max-width:1400px) {
    .Account-request {
       //  padding-left: 80px;
       //  padding-right: 40px;
    }
}

@media screen and (min-width:601px) and (max-width:1024px) {
    .Account-request {
       //  padding-left: 80px;
       //  padding-right: 40px;
    }
}

// .container{
//   max-width: 1000px;
//   margin-left: auto;
//   margin-right: auto;
//   padding-left: 10px;
//   padding-right: 10px;
// }
.responsive-header {
    li {
        border-radius: 1px;
        padding: 10px;
        width: 99.5%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgba(116, 120, 141, 0.2);

    }

    .table-header {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #464F5E;
    }

    .col {
        color: #74788D;

    }

    .col-1 {
        flex-basis: 9%;
    }

    .col-2 {
        flex-basis: 11%;
        margin-left: 20px;
    }

    .col-3 {
        flex-basis: 14%;
    }

    .col-4 {
        flex-basis: 14%;
    }

    .col-5 {
        flex-basis: 14%;
    }

    .col-6 {
        flex-basis: 14%;
    }

    .col-7 {
        flex-basis: 14%;
    }

    .col-8 {
        flex-basis: 11%;
    }

}

.responsive-table {
    li {
        border-radius: 12px;
        padding: 15px 20px;
        width:100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }

    .table-row {
       display: flex;
       justify-content: center;
       align-items: center;
        background-color: #ffffff;
        border: 1px solid rgba(116, 120, 141, 0.3);
        flex-basis: 9.3%;
    }

    .table-row:hover {
        box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        
    }

    .col {
        color: #34475A;

    }

    .col-1 {
        flex-basis: 8%;
        margin-right: 10px;
    }

    .col-2 {
       flex-basis: 11%;
       margin-left: 20px;
   }

   .col-3 {
       flex-basis: 14%;
       overflow: hidden;
       text-overflow: ellipsis;
   }

   .col-4 {
       flex-basis: 14%;
       overflow: hidden;
        text-overflow: ellipsis;
   }

   .col-5 {
       flex-basis: 14%;
   }

   .col-6 {
       flex-basis: 14%;
   }

   .col-7 {
       flex-basis: 14%;
   }

   .col-8 {
       flex-basis: 11%;
   }

    .row ul {
        margin: 0;
        padding: 0;
    }

    //  .row ul li {
    //      margin: 0;
    //      font-size: 16px;
    //      font-weight: normal;
    //      list-style: none;
    //      display: inline-block;
    //      width: 20%;
    //      box-sizing: border-box;

    //      @media only screen and (max-width:767px) and (min-width:480px) {
    //          font-size: 13px;
    //      }

    //      @media only screen and (max-width:479px) {
    //          font-size: 13px;
    //      }
    //  }

    //  .row ul li {
    //      margin: 0;
    //      font-size: 16px;
    //      font-weight: normal;
    //      list-style: none;
    //      display: inline-block;
    //      width: 20%;
    //      box-sizing: border-box;

    //      @media only screen and (max-width:767px) and (min-width:480px) {
    //          font-size: 13px;
    //      }

    //      @media only screen and (max-width:479px) {
    //          font-size: 13px;
    //      }
    //  }

    //  @media all and (max-width: 767px) {
    //      .table-header {
    //          display: none;
    //      }

    //      // .table-row {}

    //      li {
    //          display: block;
    //      }

    //      .col {

    //          flex-basis: 100%;

    //      }

    //      .col :before {
    //          display: flex;
    //          padding: 10px 0;
    //          color: #6C7A89;
    //          padding-right: 10px;
    //          content: attr(data-label);
    //          flex-basis: 50%;
    //          text-align: right;
    //      }
    //  }
}

@media screen and (min-width:1400px) {}

@media screen and (max-width:600px) {
    .Account-request {
       //  padding-left: 20px;
       //  padding-right: 20px;
    }

    // .allrequesttitle {
    //     width: 115px;
    //     height: 26px;
    //     // left: 280px;
    //     top: 168px;
    //     right: 46px;
    //     font-family: 'Open Sans';
    //     font-style: normal;
    //     font-weight: 500;
    //     font-size: 20px;
    //     line-height: 26px;
    //     color: #74788D;
    // }

}

@media screen and (min-width:601px) and (max-width:1400px) {
    .Account-request {
       //  padding-left: 80px;
       //  padding-right: 40px;
    }



}

@media screen and (min-width:601px) and (max-width:800px) {

    .responsive-header {
        li {
            border-radius: 1px;
            padding: 15px 20px;
            width: 30rem;
            display: flex;
            justify-content: space-between;
            border-top: 1px solid rgba(116, 120, 141, 0.2);

        }

        .table-header {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #464F5E;
        }

        .col {
            color: #74788D;

        }

        .col-1 {
            flex-basis: 30%;
        }

        .col-2 {
            // flex-basis: 7.3%;
            display: none;
        }

        .col-3 {
            flex-basis: 40%;
        }

        .col-4 {
            flex-basis: 30%;
        }

        .col-5 {
            display: none;
            flex-basis: 12.3%;
        }

        .col-6 {
            display: none;
            flex-basis: 10.3%;
        }

        .col-7 {
            display: none;
            flex-basis: 8%;
        }

        .col-8 {
            display: none;
            flex-basis: 9.3%;
        }

    }

    .responsive-table {
        li {
            border-radius: 12px;
            padding: 15px 20px;
            width: 30rem;
            height: 50px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
        }

        .table-row {
            background-color: #ffffff;
            border: 1px solid rgba(116, 120, 141, 0.3);
            flex-basis: 9.3%;
        }

        .table-row:hover {
            box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
        }

        .col {
            color: #74788D;

        }

        .col-1 {
            flex-basis: 30%;
        }

        .col-2 {
            flex-basis: 30%;
            // padding-left: 10px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            display: none;
        }

        .col-3 {
            flex-basis: 30%;
            font-style: bold;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .col-4 {
            flex-basis: 30%;
            font-style: normal;
            font-weight: 600;
            color: #34475A;
            font-size: 14px;
            line-height: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .col-5 {
            flex-basis: 12.3%;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            display: none;

        }

        .col-6 {
            flex-basis: 9.3%;
            font-style: normal;
            font-weight: 400;
            color: black;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: none;

        }

        .col-7 {
            flex-basis: 8%;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            display: none;

        }

        .col-8 {
            flex-basis: 9.3%;
            display: none;

        }

        .row ul {
            margin: 0;
            padding: 0;
        }
    }
}