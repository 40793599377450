.selectListofAccount{
    /* padding-left: 280px; */
    /* padding-right: 43px; */
    /* padding-top: 10px; */
    /* padding-bottom: 20px; */
    position: relative;
}

@media screen and (max-width:600px) {
    .selectListofAccount {
        /* padding-left: 20px;
        padding-right: 20px; */
    }
}
@media screen and (min-width:1024px) and (max-width:1400px) {
    .selectListofAccount {
        /* padding-left: 80px;
        padding-right: 40px; */
    }
}
@media screen and (min-width:601px) and (max-width:1024px) {
    .selectListofAccount {
        /* padding-left: 80px;
        padding-right: 40px; */
    }
}