.factor-table {
  border-collapse: collapse;
  padding-top: 5px;
  width: 100%;
}

.factor-table .text-header {
  color: #ffffff !important;
  background: #3369a6;
  font-size: 9px !important; 
  border-bottom: 0.5px solid rgba(116, 120, 141, 0.3);
  padding: 2px 5px;
  line-height: unset;
}
.factor-table .table-body {
  border: 0.5px solid #adc3db;
  border-bottom: 0.5px solid #adc3db !important;
  border-top: none !important;
}

.byFactorIcon {
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #adc3db;
  border-radius: 50%;
}
.byFactorIcon img {
  width: 10px;
}
.show-icon-factor {
  display: flex;
  flex-direction: column;
}
.show-icon-factor-table p {
  font-size: 8px;
  margin: 0px 3px;
  color: var(--headerColor);
}
.show-icon-factor-table {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}
