.auth-layout {
    background-color: rgb(0, 42, 89);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .login-form {
        background-color: white;
        border: 1px solid rgba(116, 120, 141, 0.3);
        box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
        border-radius: 10px;
        width: 420px;
        padding: 50px 60px 30px;
    }
    .ellipse-shape {
        position: absolute;
        left: -10px;
        top: 0px;
    }
    .a-shape {
        position: absolute;
        bottom: 84px;
        right: 94px;
    }
    .login-form-container{
        // margin-top: 40px;
    }
    .form-label{
        color: #002A59;
    }
    .btn-blue{
        background-color: #3369A6;
    }
}

body>div:not(#root) div:has(>iframe) {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    right: 50% !important;
    transform: translate(-50%, -50%) !important;
    -webkit-transform: translate(-50%, -50%) !important;
}

@media screen and (min-width:1720px) {
    .login-form {
        // padding: 30px 65px !important;
    }
}

@media screen and (min-width:601px) and (max-width:1400px) {
    .auth-layout {
        .a-shape {
            bottom: 24px;
            right: 24px;
        }
    }
}
@media screen and (max-width:600px) {
    .auth-layout {
        .a-shape {
            bottom: 16px;
            right: 10px;
        }
        .ellipse-shape {
            left: -80px;
            top: -100px;
        }
        .login-form {
            width: 300px;
            padding: 30px;
        }
    }
}