.trade-adhoc .form-label-header {
    font-size: 17px;
    font-family: 'Open Sans';
    font-weight: 600;
    margin-bottom: 16px;
    color: #74788D;
    line-height: 21px;
}

.trade-adhoc .tab-header {
    text-transform: none;
    font-size: 16px;
    line-height: 21px;
    color: #34475A !important;
}

.trade-adhoc .card-layout {
    width: 100%;
    background: rgba(221, 221, 221, 0.06) !important;
    border: 1px solid rgba(116, 120, 141, 0.3) !important;
    box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2) !important;
    border-radius: 10px !important;
    margin-top: 18px !important;
}