.mfaPage {
  /* padding-left: 280px; */
  /* padding-right: 43px; */
  padding-top: 70px;
  /* padding-bottom: 20px; */
  position: relative;
}

.help-link{
  color: #3B79BF;
  font-size: 14px;
  cursor: pointer;
}


@media screen and (max-width: 600px) {
  .mfaPage {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 601px) and (max-width: 1400px) {
  .mfaPage {
    /* padding-left: 80px;
    padding-right: 40px; */
  }
}
