.hading {
  position: relative;
}
.hading_parent {
  width: fit-content;
  border: 1px solid #8b3dff;
  display: block;
}

.heading .textarea {
  border: 1px solid black;
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
}

.heading .textarea:focus {
  outline: none;
}

.paragraph-text{
  min-height: 38px;
}

.title-text{
  min-height: 58px;
}

