.section-info {
    background: #44546a;
    color: white;
    font-weight: 400;
}

.section-info .bordered-div {
    border-top: 0.5px solid white;
    height: 5px;
    border-bottom: 0.5px solid white;
    margin-bottom: 25px;
}


.section-info .username,.section-info .section-title,.section-info .section-sub-title{
    padding-left: 20px !important;
}