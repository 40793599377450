.aggregate-onboarding {
  .card-layout {
    background: rgba(221, 221, 221, 0.06) !important;
    border: 1px solid rgba(116, 120, 141, 0.3) !important;
    box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2) !important;
    border-radius: 10px !important;
  }

  #background-change-hover {
    transition: background-color 0.2s;
    line-height: 1.6;
    font-size: 1.25rem;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  // #background-change-hover:hover {
  //   background-color: #091E420F;
  // }

  #edit-icon {
    visibility: hidden;
    padding: 4px;
  }

  #groupName:hover #edit-icon {
    visibility: visible;
  }

  #aggregate-name-inp {
    font: inherit;
    color: inherit;
    line-height: 1.6;
    font-size: 1.25rem;
    font-weight: 500;
    overflow: hidden;
    outline: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 300px;
    padding: 0;
    border: none;
  }

  .border-animation::after {
    position: absolute;
    content: ' ';
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    display: block;
    opacity: 1;
    height: 2px;
    transform-origin: center 0;
    animation: borderExapnd 0.2s ease;
  }

  @keyframes borderExapnd {
    from {
      transform: scaleX(0);
    }

    to {
      transform: scaleX(1);
    }
  }
}