.mainPage .table_header_left {
  font-size: 14px;
  color: #a0a0a0;
}
.mainPage .static-heder {
  position: sticky;
  top: 0;
  background-color: #ffffff;
}
.mainPage tbody tr td,
.mainPage thead tr th {
  padding: 20px 0;
  text-align: left;
  font-size: 14px;
}
.mainPage thead tr th {
  font-weight: 500 !important;
}
.mainPage tbody tr td {
  color: var(--primaryColor) !important;
}
.mainPage tbody tr td button {
  background-color: transparent;
  border: none;
  padding: 7.5px;
}
.mainPage tr td button svg {
  width: 18px;
  height: 18px;
}
.template-title {
  color: var(--headerColor);
  font-weight: 600;
  font-size: 14px;
}
.template-text {
  color: var(--primaryColor);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  max-width: 200px;
  white-space: break-spaces;
}
/*====================*/
.table_main_div {
  padding: 0px 30px 20px;
}
.table_header_left {
  font-size: 14px;
  color: #a0a0a0;
  padding-left: 10px;
}
.name_width {
  width: 35%;
}
.Modified_width {
  width: 20%;
}
.Report_width {
  width: 28%;
}
.border-bottom {
  border-bottom: 0.5px solid #a0a0a06e;
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .mainPage tbody tr td,
  .mainPage thead tr th {
    padding: 20px 0px;
  }
  /* .template-text {
    width: 75%;
  } */
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mainPage tbody tr td,
  .mainPage thead tr th {
    padding: 20px 0px;
  }
  /* .template-text {
    width: 90%;
  } */
  .Modified_width {
    width: 25%;
  }
  .Report_width {
    width: 20%;
  }
}
