.pdf-customizer-summary-table .table-component {
  font-family: 'Open Sans', sans-serif;
  color: black;
  border: 0;
  padding-top: 12px;
}

.table-component table {
  border-collapse: collapse;
  width: 100%;
}

.table-component thead th {
  width: fit-content;
}

.table-component th {
  vertical-align: bottom;
  font-size: 6px !important;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0.5px;
  color: black !important;
  padding: 0;
  padding-inline: 2.5px;
}

.table-component td {
  font-size: 6.5px;
  font-weight: 400;
  line-height: 11px;
  padding-inline: 2.5px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #333333;
}

.table-component .headingBorder {
  border-bottom: 0.5px solid #d0d0d0;
}

.table-component .borderBottom {
  border-bottom: 0.5px solid #e9e9e9;
}

.table-component .gain_loss_ul {
  list-style: none;
  margin-bottom: 1px;
  margin-top: 1px;
}
.table-component .isTotal .gain_loss_ul_1 {
  text-align: left;
  font-size: 7px;
}
.table-component .isTotal {
  background-color: #f4f4f4;
  color: black;
  font-weight: 400;
}

.table-component .isTotal .gain_loss_ul_2 {
  text-align: right;
  font-size: 8px;
}
.table-component .gain_loss_ul_1 {
  text-align: left;
  font-size: 6px;
}
.table-component .gain_loss_ul_2 {
  text-align: right;
  font-size: 6px;
}
.table-component .total_li {
  background-color: #f4f4f4;
  padding: 2px 4px;
}
.table-component .total_li div {
  font-size: 11px !important;
}
.table-component .loss_gain {
  width: 35% !important;
}
.table-component table tr td:nth-child(2),
.table-component table tr td:last-child {
}
