html {
  background-color: black;
}

.reportPage {
  /* padding-left: 280px; */
  /* padding-right: 43px; */
  /* padding-top: 20px; */
  /* padding-bottom: 20px; */
  position: relative;
  margin-top: 0px !important;
}

.chart-container {
  width: 100%;
  height: 400px
}

.trade-table-list-header {
  font-family: 'Open Sans' !important;
  font-weight: 600 !important;
  font-size: 17px;
  line-height: 21px;
  margin-bottom: 16px !important;
  margin-top: 22px !important;
  color: #74788D !important;
}

.account-label-header.MuiTypography-root {
  font-size: 17px;
  font-family: 'Open Sans';
  font-weight: 600;
  margin-bottom: 16px;
  color: #74788D;
  line-height: 21px;

}

.asset-table-thead tr th {
  font-family: 'Open Sans' !important;
  text-align: right;
}

.asset-table-tbody tr td {
  font-family: 'Open Sans' !important;
  text-align: right;
  padding-right: 16px;
}

.asset-table-tbody tr td:nth-child(1),
.asset-table-tbody tr td:nth-child(2) {
  text-align: left;
}

.report-tab-header {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #34475A !important;
  margin-left: 10px !important;
}

.trade-tab-header {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #34475A !important;
  margin-left: 130px !important;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 53px;
  height: 53px;
  background: rgba(116, 120, 141, 0.1);
  border-radius: 5px;
}

.tax-state-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.market-tab-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #74788D;
}

.card-layout {
  background: rgba(221, 221, 221, 0.06) !important;
  border: 1px solid rgba(116, 120, 141, 0.3) !important;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2) !important;
  border-radius: 10px !important;
}

.accordion-layout {
  border: 1px solid rgba(116, 120, 141, 0.3);
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2) !important;
  border-radius: 10px !important;
}

.row-header {
  width: 35px;
  height: 38px;
  transform: rotate(-90deg);
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #34475A;
}

.column-header {
  width: 80px;
  height: 20px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #34475A;
}

.btn-style {
  min-width: 27px !important;
  height: 14px;
  font-size: 10px !important;
}

.TradeList-Table-module .MuiDataGrid-columnHeaderTitleContainer {
  flex: none !Important;
}

.box-class {
  display: flex;
  align-items: baseline;
  column-gap: 10px;
}

.table-header {
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 21px;
  color: #74788D !important;
}

.table-cell {
  font-family: 'Open Sans' !important;
  font-weight: 600 !important;
  font-size: 12px;
  color: #34475A !important;
}

.table-cell-styles {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  color: #34475A !important;
  width: 50% !important;
}

.table-cell-styles1 {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  color: #34475A !important;
  width: 50%;
}

.table-cell-styles {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  color: #34475A !important;
  width: 50% !important;
}

.table-cell-styles1 {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  color: #34475A !important;
  width: 50%;
}

.table-head {
  font-family: 'Open Sans';
  color: #74788D !important;
  flex: auto;
  font-size: 14px !important;
}

.table-head-summary {
  font-family: 'Open Sans' !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #74788D !important;
}

.unrealized-column-header{
  padding: 4px !important;
}

.unrealized-column-header:not(.top-column){
  border-bottom: none !important;
}

.table-body {
  box-shadow: none !important;
}

.table-body1 {
  box-shadow: none !important;
  width: 70% !important;
}


.table-cell-allocation td,
.table-cell-allocation th {
  text-align: right;
}

.table-cell-allocation thead th:first-child,
.table-cell-allocation tbody td:first-child {
  text-align: left;
}


.reportCardSub {
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  color: #34475A;
  font-size: 14px !important;
}

.reportCardNav {
  font-family: 'Open Sans' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px;
  color: #34475A !important;
  text-transform: none !important;
}

.stack-container-wapper {
  padding: 8px;
}



.summaryReportWrapper>table>thead>tr>th {
  width: 9.44%;
  padding: 10px 4px;
}

.summaryReportWrapper>table>tbody td {
  font-family: 'Open Sans' !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #34475A !important;
  text-align: center !important;
  line-height: 14px !important;
}

.summaryReportWrapper>table>thead>tr>th:nth-child(4),
.summaryReportWrapper>table>thead>tr>th:nth-child(6),
.summaryReportWrapper>table>thead>tr>th:nth-child(7) {
  width: 5%;
}

.chart-label {
  font-family: 'Open Sans' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #74788D;
}

.ScenarioSelectionValues {
  border: 1px solid #ccc;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 5px !important;
  box-shadow: none !important;
  font-size: 16px;
  padding: 8px 10px;
}

.ScenarioSelectionValues .dropdown.icon {
  right: 10px;
}

.ScenarioSelectionValues:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.label-box {
  width: 20px;
  height: 11px;
  background: #4B8CCA;
}

.no-report {
  text-align: center;
  max-width: 600px;
  color: #74788D;
}

.summaryReportWrapper>table>tbody td span[role=progressbar] {
  margin-top: 8px;
}

#chart-container .apexcharts-yaxistooltip-0 {
  border: none;
  background-color: #2962ff;
  color: white !important;
}

#chart-container .apexcharts-yaxistooltip-0::before,
#chart-container .apexcharts-yaxistooltip-0::after,
#chart-container .apexcharts-yaxistooltip-1::before,
#chart-container .apexcharts-yaxistooltip-1::after {
  display: none;
}

#chart-container .apexcharts-yaxistooltip-1 {
  border: none;
  background-color: #33A68D;
  color: white !important
}

.trade-columns {
  display: flex;
  justify-content: space-between;
}

.trade-section {
  flex: 1;
  margin-top: -20px;
}

.trade-section:first-child {
  margin-right: 10px;
}


.trade-row {
  display: flex;
  column-gap: 10px;
  margin-left: -5px;
  margin-top: -20px;
  height: 40px;
}


.symbol{
  width: 50%;
}

.buy-trade {
  font-weight: 600;
  width: 50%;
  text-align: right;
  color: #33A68D;
}

.sell-trade {
  font-weight: 600;
  width: 50%;
  text-align: right;
  color: rgb(211, 47, 47)  ;
}

@media screen and (max-width: 1000px) {

  .asset-table-thead tr th {
    text-align: left;
  }

  .asset-table-tbody tr td {
    text-align: left;
    padding-right: 8px;
  }

  .asset-table-tbody tr td:nth-child(1),
  .asset-table-tbody tr td:nth-child(2) {
    text-align: left;
  }

}


.no-data-text {
  text-align: center;
  color: #34475A;
  font-size: 14px !important;
}

#market-chart {
  position: relative;
}

#market-chart .apexcharts-point-annotation-marker {
  display: none;
}

#market-chart .apexcharts-point-annotations .apexcharts-point-annotation-marker~rect {
  cursor: pointer !important;
}

.red-border {
  background: #fff;
  border-left: 4px solid #d32f2f;
  border-radius: 4px;
  box-shadow: 0 2px 4px #787b8666;
  box-sizing: border-box;
  color: #131722;
  padding: 12px 12px;
  width: 250px;
  z-index: 15;
}

.green-border {
  background: #fff;
  border-left: 4px solid #33A68D;
  border-radius: 4px;
  box-shadow: 0 2px 4px #787b8666;
  box-sizing: border-box;
  color: #131722;
  padding: 12px 12px;
  width: 250px;
  z-index: 15;
}

.blue-border {
  background: #fff;
  border-left: 4px solid #002A59;
  border-radius: 4px;
  box-shadow: 0 2px 4px #787b8666;
  box-sizing: border-box;
  color: #131722;
  padding: 2px 8px;
  width: 250px;
  z-index: 15;
}


.green-border #heading,
.red-border #heading,
.blue-border #heading {
  margin: 0;
  margin-bottom: 12px;
}

.green-border b,
.blue-border b,
.red-border b {
  font-weight: normal;
}

.green-border #data-row b:last-child,
.red-border #data-row b:last-child,
.blue-border #data-row b:last-child,
.green-border #date-row b:last-child,
.blue-border #date-row b:last-child,
.red-border #date-row b:last-child {
  font-weight: 600;
}

.green-border #data-row p:first-child,
.red-border #data-row p:first-child,
.green-border #date-row p:first-child,
.red-border #date-row p:first-child {
  margin: 0 12px 0px 0px;
  width: 80px !important;
  display: inline-block;
}