.angel {
  position: absolute;
  top: 80px;
  right: 39px;
  width: 128px;
}

.logo-cover-page {
  width: 69px;
}
.coverpage1 {
  position: relative;
  padding-top: 160px;
}
.margin-top-100 {
  margin-top: 100px;
}

.coverpage1 .fontsize20 {
  font-size: 20px;
  font-weight: 500;
  /* color: #002859; */
}

.coverpage1 .divider1 {
  width: 70px;
  height: 1px;
  background-color: #0028597a;
}

.coverpage1 .divider2 {
  width: 50px;
  height: 1px;
  background-color: #0028597a;
}
.coverpage1 .fontsize8 {
  font-size: 8px;
  font-weight: 500;
  margin-bottom: 2px;
  font-family: 'Open Sans', sans-serif;
  color: #002859;
}
