.newLayout{
    margin: 80px 10px 80px 260px;
}
.pdfLay{
    padding: 0 180px 0;
    background-color: #D3D3D3;
}
.pdfpd{
    padding: 20px 50px 80px 20px;
    background-color: #FFFFFF;
}
.pdfLayout{
    margin-left: 30px;
    
}

.pdfLogo{
    width: 150px;
}

.pdfTableOfContents{
    min-height: 680px;
    max-height: auto;
    margin-top: 30%;

    .dividerPdf {
        border-radius: 4px;
        width: 8px;
        height: 200px;
        background: #CCD2DD;
    }

    .pdfContents{
        color: #B3B3B3;
    }
}

.AccountKeyTaxPdf{
    color: #969696;
    min-height: 600px;
    max-height: auto;
    margin-bottom: 8%;
}

.AccountKeyPdf{
    color: #969696;
    min-height: 925px;
    max-height: auto;
    margin-bottom: 8%;

    .dividerPdf1{
        border-radius: 4px;
        width: 8px;
        height: 40px;
        background: #CCD2DD;
    }

    .text-headPdf {
        font-size: 22px;
        line-height: 2rem;
        font-weight: 600;
    }

    .bodyPdf{
        margin-left: 20px;

        .secHeadPdf{
            font-size: 20px;
            font-weight: 600;
            margin: 20px 0 30px 0;
        }

        .listPdfdiv{
            margin: 20px 0;
            .listPdf{
                list-style: none;
                font-weight: 500;
                .dataPdf{
                    font-size: 18px;
                    color: #000;
                }
                .titlePdf{
                    margin-top: -3px;
                    font-size: 12px;
                }
            }
        }
        
    }
    
}

.AccountAssetAllocationPdf{
    margin-top: 10px;
}

.AccountPortfolioPdf{
    color: #969696;
    .dividerPdf1{
        border-radius: 4px;
        width: 8px;
        height: 40px;
        background: #CCD2DD;
    }

    .text-headPdf {
        font-size: 22px;
        line-height: 2rem;
        font-weight: 600;
    }

}

.mktValuePdf{
    margin-left: 83%;
    .valuePdf{
        color: #000;
        font-weight: 500;
    }
    .tmvPdf{
        color: #000;
    }
}

.total-saved-value-position{
    margin: 80px 40px 0;
    background: #d4e3fb;
    font-size: 18px;
}

.taxSavedValue{
    display: block;
    padding: 8px;
}

.taxValue-head{
    width: 400px;
    font-weight: 500;
}

.taxValue{
    margin-left: auto;
    display: block;
}

.total-val{
    font-size: 20px;
    font-weight: 500;
}

.taxSavedTableHead{
    font-weight: 500;
    color: #000;
    margin-bottom: 10px;
}

// User View

.userViewPdf{
    margin-top: 70px;
    margin-left: 250px;
}

.pdfTableOfContentsUserView{
    margin-top: 15%;
    height: 400px;

    .dividerPdf {
        border-radius: 4px;
        width: 8px;
        height: 200px;
        background: #CCD2DD;
    }

    .pdfContents{
        color: #B3B3B3;
    }
}

div {
    page-break-inside: avoid;
 }
 #nextpage1 {
   page-break-before: always;
 }

 .downloadButtonPdf{
      border: medium none;
      color: #FFFFFF;
      height: 50px;
      left: 40%;
      position: fixed;
      bottom: 15px;
 }