// @import "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&family=Montserrat:wght@500&display=swap";
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

/* .card {
    background-color: theme('colors.white');
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(116, 120, 141, 0.5);
    padding: 24px;
    border: 1px solid #74788d4c;
} */
body {
    font-family: 'Open Sans', sans-serif !important;
    // overflow: visible !important;
    margin: 0;
    padding: 0;
    background-color: #fff;
    color: #212529;
}

.bg-green {
    background-color: #169D6C !important;
}

.bg-yellow {
    background-color: #EFAE3A !important;
}

.bg-red {
    background-color: #F05F5F !important;
}

.bg-blue {
    background-color: #175CC2 !important;
}

.btn-blue {
    background: #175CC2;
    color: white !important;
    font-weight: 500 !important;
}

.chip {
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #74788D1A;
    color: #74788D;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.table-responsive {
    max-height: 750px;
    display: block;
    width: 100%;
    overflow-x: auto !important;

    table {
        width: 100%;
        margin-bottom: 1rem;
        background-color: transparent;
        display: table;
        box-sizing: border-box;
        text-indent: initial;
        border-spacing: 2px;

        thead {
            tr {
                border-bottom: 1px solid #74788D4D;
            }

            th {
                color: #74788D;
            }
        }

        tbody {
            tr {
                border-bottom: 0.5px solid #74788D4D;
            }
        }

        th {
            text-align: left;
            padding: 14px 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #34475A;
            min-width: 50px;
            white-space: nowrap;
        }

        td {
            text-align: left;
            padding: 14px 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #34475A;
            min-width: 50px;
            white-space: nowrap;
        }

        tr {
            th {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    text-align: left;
                }
            }

            td {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    text-align: left;
                }
            }
        }
    }
}

.modal-body {
    .broder-fixed {
        max-height: 330px;
        overflow-y: auto;
        position: relative;
    }

    .broder-fixed table thead {
        top: 0;
        position: sticky;
        z-index: 1;
    }

    .broder-fixed table thead tr {
        background: #fff;
        box-shadow: 0px 0.2px rgb(116 120 141 / 30%)
    }
}

.table-responsive-import {
    display: block;
    width: 100%;
    // min-height: 250px;
    max-height: 650px;
    overflow-x: auto;
    overflow-y: auto;

    table {
        width: 100%;
        margin-bottom: 1rem;
        background-color: transparent;
        display: table;
        box-sizing: border-box;
        text-indent: initial;
        border-spacing: 2px;

        thead {
            tr {
                border-bottom: 1px solid #74788D4D;
            }

            th {
                color: #74788D;
            }
        }

        tbody {
            tr {
                border-bottom: 0.5px solid #74788D4D;
            }
        }

        th {
            text-align: left;
            padding: 14px 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #34475A;
            min-width: 50px;
            white-space: nowrap;
        }

        td {
            text-align: left;
            padding: 14px 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #34475A;
            min-width: 50px;
            white-space: nowrap;
        }

        tr {
            th {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    text-align: left;
                }
            }

            td {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    text-align: left;
                }
            }
        }
    }
}

.custom-cell {
    font-weight: 600;
}

.btn-grey {
    border: none;
    background-color: #74788D14 !important;
    padding: 6px 12px;
    font-weight: bold;
    color: #74788D;
}

.Pagenotfound {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
    position: absolute;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

@media screen and (min-width: 1401px) and (max-width: 5128px) {
    .Pagenotfound {
        padding-left: calc(100% - calc(100% - 240px));
    }

    // body[data-legacy-view="true"] {
    //     padding-left: calc(100% - calc(100% - 240px));
    // }
}

.text-title {
    color: #74788D !important;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 21px !important;
    margin-bottom: 3px !important;
}

.text-title.header-text {
    font-size: 17px;
}

.progressbar {
    height: 5px;
    width: 30px;
    background-color: #74788D33;
    border-radius: 5px;
    margin-right: 8px;
}

.page-title {
    font-family: 'Lora' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 26px !important;
    color: #34475A !important;
    // padding-top: 8px;
}

.divider {
    border-radius: 4px;
    width: 3.05px;
    height: 53.8px;
    background: #F46A6A;
}

.divider-trade {
    border-radius: 1px;
    width: 1px;
    height: 20px;
    background: #6E8195;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.form-label {
    color: #464F5E;
    margin-left: 20px;
}

.form-input-group {
    border: 0.5px solid #6E8195;
    display: flex;
    border-radius: 500px;
    padding: 12px 20px;
    align-items: center;

    input {
        border: 0;

        &:focus {
            outline: none;
        }
    }
}

.help-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #464F5E;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.mt-7 {
    margin-top: 36px;
}

.fs-10 {
    font-size: 10px !important;
    line-height: 10px !important;
}

.fs-14 {
    font-size: 14px !important;
}

ol {
    margin: 0 !important;
    padding-left: 0 !important;
}

ul {
    margin: 0 !important;
    padding-left: 0 !important;
}

dl {
    margin: 0 !important;
    padding-left: 0 !important;
}

.font-medium {
    font-weight: 500;
}

.line-height-30 {
    line-height: 30px !important;
}

.white-nowrap {
    white-space: nowrap !important;
}

.dropdown-toggle.arrow-none {
    &::after {
        display: none;
    }
}

.linkId {
    color: #34475A;
    text-decoration: none;
}

.linkId:hover {
    cursor: pointer;
    text-decoration: none;
}

@media screen and (min-width:1720px) {
    body {
        max-width: 1720px;
        margin: 0 auto !important;
        padding: 0 !important;
        margin-right: auto !important;
    }

    body.model-open {
        margin-right: auto !important;
        padding-right: 0;
        overflow: visible !important;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.pagination-rounded {
    display: flex;
    flex-wrap: wrap;

    .page-item {
        .page-link {
            border-radius: 50% !important;
            font-size: 14px;
            color: #74788D;
            font-weight: 500;
            margin: 5px;
            border: none !important;
        }

        &.active {
            .page-link {
                background-color: #74788D !important;
                border-color: #74788D;
                color: white !important;
                box-shadow: none !important;
            }
        }
    }
}

.modalshow {
    margin-left: 8%;
}

.awaiting-badge {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    text-align: right;
    color: #34475A;
    background: rgba(116, 120, 141, 0.15);
    border-radius: 5px;
    padding: 4px 8px;
    height: fit-content;
}

.completed-badge {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    text-align: right;
    color: #34475A;
    background: rgba(23, 92, 194, 0.08);
    border-radius: 5px;
    padding: 4px 8px;
    height: fit-content;
}

.text-blue {
    color: #175CC2;
}

.global_submit_btn {
    background-color: #175CC2;
    color: white;
    border: none;
    width: auto;
    padding: 0 20px 0 20px;
    height: 36px;
    border-radius: 36px;

}

.custom-textfield {
    width: 100%;
    padding: 8px 12px;
    border: none;
    outline: none;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: transparent;
    transition: border-color 0.2s ease-in-out;

    &:disabled {
        color: rgba(0, 0, 0, 0.38);
        cursor: not-allowed;

        &::placeholder {
            color: rgba(0, 0, 0, 0.38);
        }

        &.is-inserted {
            color: #34475A;
        }
    }
}

.custom-textfield:focus-within {
    border-color: #2196f3;
}

.error-border {
    border-color: rgb(220, 53, 69);
}

.risk-page-table {

    th,
    td {
        padding: 14px 10px !important;
        font-size: 14px !important;
    }

    th {
        white-space: nowrap;
    }
}

.apexcharts-legend-text {
    cursor: pointer;
}

.sync-icon-rotate.rotate {
    animation: rotate 2s ease-in 0s infinite;
}

.account-details-container {
    .summary-header {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #74788D;
        margin-bottom: 12px;
    }

    .sub-title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: #34475A;
        margin-bottom: 16px;
    }

    .sub-labels {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #74788D;
        margin-bottom: 8px;
    }

    .label-value-wrapper {
        background: #F1F3F4;
        border-radius: 5px;
        padding: 10px;
    }

    .label-value {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #34475A;
    }

    .card-layout {
        background: rgba(221, 221, 221, 0.06) !important;
        border: 1px solid rgba(116, 120, 141, 0.3) !important;
        box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2) !important;
        border-radius: 10px !important;
        margin-top: 18px !important;
    }
}

.submitBox li {
    list-style: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    color: #74788d;
}

.list-item {
    width: 50%;
}

th {
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 21px;
    color: #74788D !important;
}

input::-webkit-file-upload-button:hover {
    cursor: pointer;
}

.card-subheading {
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #34475A;
    font-size: 14px !important;
}

.stack-container {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.report-card {
    background: rgba(221, 221, 221, 0.06);
    border: 1px solid rgba(116, 120, 141, 0.3);
    box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2) !important;
    border-radius: 10px !important;
}

.report-card-border>div {
    border-right: 1px solid rgba(116, 120, 141, 0.3);
    display: grid;
    justify-content: space-around;
    align-items: center;
}

.report-card-border>div:last-child {
    border-right: 0px solid rgba(116, 120, 141, 0.3);
}

.card-heading {
    font-family: 'Open Sans' !important;
    font-weight: 400 !important;
    line-height: 29px !important;
    color: #0066C7 !important;
    font-size: 22px !important;
}

.report-card-border .stack-container {
    padding: 2px 10px;
}

@media screen and (max-width: 1000px) {
    .report-card-border>div {
        -webkit-flex-basis: 50% !Important;
        -ms-flex-preferred-size: 50% !Important;
        flex-basis: 50% !Important;
        max-width: 50% !Important;
        margin-top: 20px;
        justify-content: left;
    }
    
    .report-card-border>div:nth-child(2),
    .report-card-border>div:nth-child(4) {
        border: 0;
    }

    .report-card-border {
        width: 100% !Important;
        max-width: 100% !Important;
    }
}

.comment-box{
    margin-bottom: 10px;
    max-height: 250px
}