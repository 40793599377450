  .uma-import-portfolio {
    background: rgba(116, 120, 141, 0.05);
    border: 0.5px dashed rgba(116, 120, 141, 0.5);
    border-radius: 5px;
    padding: 0 15px;
    height: 160px;
    width: 800px;

    p {
        margin-bottom: 0;
    }

    h5 {
        font-size: 16px !important;
        font-family: "Open Sans";
        font-weight: 600;
    }

    .btn-soft-blue {
        background: rgba(23, 92, 194, 0.5);
        border-radius: 5px;
        border: none;
    }

    .btn-soft-red {
        background: #F05F5F26;
        border: none;
        color: #F05F5F;
        font-weight: bold;
    }

    input[type="file"] {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
    }

    .uploaded-file {
        background-color: white;
        border-radius: 5px;
        padding: 8px;
        margin: 8px 0;
        height: 110px;
        margin-left: 30px;
        font-family: "Open Sans";

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #74788D;
        }

        button {
            font-size: 12px !important;
        }
    }
}

.factor-tilt-table-header {
  font-family: 'Open Sans';
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 18px;
  color: #74788D;
}


.submitBox img {
  margin-top: 40px;
}

.submitBox h4 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 26px;
  line-height: 34px;
}

.border-animation::after {
  position: absolute;
  content: ' ';
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  display: block;
  opacity: 1;
  background: #1976d2;
  height: 2px;
  transform-origin: center 0;
  animation: borderExapnd 0.2s ease;
}

.border-animation.error::after {
  background: red; 
}

@keyframes borderExapnd {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

