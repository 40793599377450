.page {
  width: 465px;
  height: 602px;
  overflow: hidden;
  background-color: #ffffff;
  margin: 5px 0;
  box-shadow: 0 2px 8px rgba(14, 19, 24, 0.07);
  /* padding: 0.9rem; */
}
.isScrollTrue {
  box-shadow: 0 2px 10px rgba(255, 1, 1, 0.2) !important;
}
.add-page-btn {
  width: 465px;
  border: none;
  border-radius: 10px;
  padding: 7px;
  color: var(--primaryColor);
  font-size: 14px;
  background: #DDE1E7;
  font-weight: 400;
  font-family: 'Open Sans';
}
.font-open-sans {
  font-family: 'Open Sans';
}
.example-config {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 99;
}

.group {
  transition: 0.5s;
  border: 1px transparent solid;
}
.main-page {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* overflow: auto;

  height: calc(100vh - 109px); */
}

/*======================================*/
.isActive {
  border: 1px #002A59 solid !important;
  border-radius: 0;
  overflow: hidden;
}

/*======================================*/
.chart-border {
  border-width: 0.5px;
  border-color: #dedfe4;
  border-style: solid;
  border-radius: 3px;
}
.transparent-border{
  border: 1px transparent solid;
  border-radius: 0;
  overflow: hidden;
}
.side-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 15.5%;
  bottom: 4%;
  z-index: 9;
}
.btn-page-control {
  width: 30px;
  height: 30px;
  background: var(--white);
  border: 1px solid var(--primaryColor);
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: var(--primaryColor);
  margin-bottom: 10px;
  text-align: center;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  z-index: 1;
}
.btn-page-control:hover {
  background: #eef;
}

/*======================================*/
.page .isFooter {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
}

.element-wrapper>.drag-handle{
  display: none;
}
.element-wrapper:hover>.drag-handle {
  display: flex;
}
.element-wrapper.isActive>.drag-handle {
  display: flex;
}
.loading-text{
  font-size: 10px;
}
/* .pdf-customizer .react-grid-item>.react-resizable-handle.react-resizable-handle-nw {
  top: -4px !important;
  left: -4px !important;
}

.pdf-customizer .react-grid-item>.react-resizable-handle.react-resizable-handle-ne {
  top: -4px !important;
  right: -4px !important;
}

.pdf-customizer .react-grid-item>.react-resizable-handle.react-resizable-handle-sw {
  bottom: -4px !important;
  left: -4px !important;
}

.pdf-customizer .react-grid-item>.react-resizable-handle.react-resizable-handle-se {
  bottom: -4px !important;
  right: -4px !important;
}


.pdf-customizer .react-grid-item>.react-resizable-handle.react-resizable-handle-s{
  transform: translateX(-50%) !important;
  bottom: -2px !important;
  margin-left: 0 !important;
}

.pdf-customizer .react-grid-item>.react-resizable-handle.react-resizable-handle-n {
  transform: translateX(-50%) !important;
  top: -2px !important;
  margin-left: 0 !important;
}

.pdf-customizer .react-grid-item>.react-resizable-handle.react-resizable-handle-e{
transform: translate(50%,-50%) !important;
margin-top: 0 !important;
}

.pdf-customizer .react-grid-item>.react-resizable-handle.react-resizable-handle-w {
  transform: translate(-50%,-50%) !important;
margin-top: 0 !important;
}

.pdf-customizer .react-grid-item>.react-resizable-handle::after{
  display: none;
} */

.pdf-customizer .react-grid-item>.react-resizable-handle {
  height: 12px;
  width: 12px;
}