.Restrictions-page{
    // padding-left: 280px;
    // padding-right: 43px;
    // padding-top: 10px;
    // padding-bottom: 20px;
    position: relative;
}

.restricted-security-block-modal {
    // background: rgba(116, 120, 141, 0.05);
    // border: 0.5px solid rgba(116, 120, 141, 0.2);
    // border-radius: 5px;
    // padding: 22px;
    .security-chip {
        background: rgba(23, 92, 194, 0.2);
        border-radius: 50px;
        color: #175CC2;
        padding: 5px 5px 5px 12px;
        font-weight: 600;
        font-size: 12px;
        margin-right: 10px;
        .remove-btn {
            background-color: #175CC2;
            border-radius: 50%;
            border: none;
            margin-left: 15px;
            img {
                width: 8px;
                height: 8px;
                margin: auto;
            }
        }
    }
}
@media screen and (min-width:1400px) {}
@media screen and (max-width:600px) {
    .Restrictions-page {
        // padding-left: 20px;
        // padding-right: 20px;
    }
}
@media screen and (min-width:601px) and (max-width:1400px) {
    .Restrictions-page {
        // padding-left: 80px;
        // padding-right: 40px;
    }
}
@media screen and (min-width:601px) and (max-width:800px) {}