.form-group label {
    font-family: "Open Sans", sans-serif;
}

.form-group {
    font-size: 14px;
    margin-bottom: 10px;
}

.form-group select {
    height: 50px;
}

.form-group input {
    height: 50px;
}

.comment-req {
    height: 114px;
}

.heighttext {
    display: inline-block;
    padding: 15px 10px;
    line-height: 500% !important;
}

.account-request {
    background: rgba(221, 221, 221, 0.06);
    border: 1px solid rgba(116, 120, 141, 0.3);
    border-radius: 10px;
    padding: 0px;
    margin: 0 0 10px 0;
    min-height: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
    .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #34475A;
    }

    .time {
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: #74788D;
        margin-bottom: 0;
        line-height: normal;
    }

    .reqComment {
        margin-top: 8px;
        margin-bottom: -2px;
        font-weight: 500;
        font-size: 16px;
        color: #464F5E;
    }
}

.reqEditCommnet {
    display: flex;

    .reqFormComment {
        width: 88.5%;
        margin-right: 1.5%;
        background: #F0F0F0;
        border-radius: 5px;
        border: none;

        :active {
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
        }
    }

    .sendIcon {
        width: 7%;
        padding: 12px;
        background: #F0F0F0;
        border-radius: 5px;
        cursor: pointer;

        :active {
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
        }
    }
}

.viewReq {
    margin-left: 1px !important;
    margin-bottom: 3px;
}

.cellClassName{
    padding-left: 60px !important;
}