.diversification-statistics {
  border: 0.31px solid #74788d4d;
  border-radius: 0;
  color: var(--headerColor);
  font-family: 'Open Sans', sans-serif;
}
.diversification-statistics div {
  width: 100%;
  padding: 5px 0;
  border-right: 0.31px solid #74788d4d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.diversification-statistics div:last-child {
  border-right: none;
}
.diversification-statistics div p {
  text-align: center;
}
.diversification-statistics div .common-text-header {
  color: #002a59;
}
