.ytdtaxsaving {
  /* padding-block: 10px; */
  padding-top: 12px;
  /* padding-inline: 2.5px; */
}
.ytdtaxsaving .fontsize8 {
  padding-inline: 2.5px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 6.5px;
  line-height: 11px;
  font-weight: 400;
  text-align: left;
  color: #333333;
}

.ytdtaxsaving th {
  font-family: 'Open Sans';
  font-size: 6px !important;
  font-weight: 500;
  line-height: 9px !important;
  letter-spacing: 0em;
  text-align: left;
  vertical-align: bottom;
  color: black !important;
  letter-spacing: 0.5px;
  padding: 0;
  padding-inline: 2.5px;
}

.ytdtaxsaving .headingBorder {
  border-bottom: 0.5px solid #d0d0d0;
}

.ytdtaxsaving .borderbottom {
  border-bottom: 0.5px solid #e9e9e9;
}

.ytdtaxsaving .w70 {
  width: 70%;
}

.ytdtaxsaving .w30 {
  width: 30%;
}

.ytdtaxsaving .tableborder {
}
