// body {
//     overflow-y: scroll;
//     scrollbar-width: thin;
// }

// body::-webkit-scrollbar {
//     width: 8px !important;
// }

// body.hide-scrollbar {
//     overflow-y: scroll;
//     padding-right: 8px;
// }

.aggregate-page {
    position: relative;
}

.dropdown-animate {
    animation: fadeInDown 1.5s ease-in;
}

@keyframes dropIn {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-100px);
    }

    to {
        transform: translateY(0);
    }
}

.group-card {
    animation: dropIn 0.5s ease-out forwards, slideDown 0.5s ease-out forwards;
}

.move-to-top {
    animation: moveToTop 1s forwards;
}

@keyframes moveToTop {
    to {
        top: 0;
    }
}

.group-card:not(:first-child) {
    animation-delay: 1s;
}

@keyframes moveAndFadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
        position: relative;
    }

    to {
        opacity: 0;
        transform: translateY(900px);
        /* Move the cards to the bottom of the page (viewport height) */
    }
}

@keyframes moveAndSettle {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
        position: relative;
    }

    30% {
        position: relative
    }

    70% {
        position: relative
    }
}

@keyframes aggregateMoveAndFadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
        // position: relative;
    }

    to {
        opacity: 0;
        transform: translateY(900px);
        /* Move the cards to the bottom of the page (viewport height) */
    }
}

@keyframes aggregateMoveAndSettle {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.view-details-account-card {
    animation: moveAndFadeOut 1.5s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    animation-timing-function: ease-in;

}

.dashboard-account-card {
    animation: moveAndSettle 1.5s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    opacity: 0;
    animation-timing-function: ease-in;
    animation-delay: 0.5s;
}

@keyframes fadeInDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0)
    }
}

@keyframes dropFromTop {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


.group-account-card {
    animation: dropFromTop 0.5s ease-out forwards, slideDown 0.5s ease-out forwards;
    animation-delay: 1s;
}


.grid-card {
    // min-width: 250px;
    // max-width: 350px;
    width: '100%';

    .text-title {
        color: rgba(47, 47, 47, 0.70);
        font-family: 'Open Sans';
        font-size: 12px !important;
        font-style: normal;
        font-weight: 400 !important;
        margin-top: 4px;
        margin-bottom: 4px !important;
        line-height: normal;
    }

    .value {
        color: #2F2F2F;
        font-family: Open Sans;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .marketValue {
        color: rgba(47, 47, 47, 0.50);
        font-family: 'Open Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mainHeader {
        color: #2F2F2F;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

}

.fade-in-card {
    opacity: 0;
    animation: fadeIn 1.5s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    animation-timing-function: ease-in;
    animation-delay: 0.5s;
}

.view-details-fade-in-card {
    animation: aggregateMoveAndSettle 1.5s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    opacity: 0;
    animation-timing-function: ease-in;
    animation-delay: 0.5s;
}

.dashboard-fade-in-card {
    animation: aggregateMoveAndFadeOut 1.5s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    animation-timing-function: ease-in;
}

.fade-out-card {
    opacity: 1;
    animation: fadeOut 1.5s ease-in forwards;
    pointer-events: none;
    /* Disable interaction during fade-out */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}


@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0%);
    }
}

.aggregate-detailed-view-card {
    animation: slideInFromRight 2s;
    animation-timing-function: ease-in;

}

.aggregate-portfolio-table .datagrid-main-row {
    background-color: #00000011;
}

.aggregate-portfolio-table .datagrid-main-row:hover {
    background-color: #00000011 !important;
}

.react-flow__panel.react-flow__attribution {
    display: none;
}

.dynamic-tree {    
    .react-flow__node {
        width: 300px;
        min-height: 120px;
        border: 0;
        text-align: left;
        border: 1px solid rgba(116, 120, 141, 0.3);
        box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
        padding: 0;
        border-radius: var(--xy-node-border-radius, var(--xy-node-border-radius-default));
        background-color: var(--xy-node-background-color, var(--xy-node-background-color-default));
    }

    .inner-box {
        // display: flex;
        // flex-direction: column;
        // height: 100px;
        // justify-content: space-between;
        display: flex;
        flex-direction: column;
        min-height: 120px;
        justify-content: space-between;
    }

    .inner-box .div-title {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        border-bottom: 0.5px solid #C5C5C5;
    }

    .inner-box .row-div {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        font-weight: bold;
        color: #74788D;
        font-size: 14px;
    }

    .inner-box .row-div span {
        display: block;
        color: #2F2F2F;
        font-size: 20px;
    }

    .react-flow__node p {
        padding: 10px;
    }

    .inner-box>p:first-child {
        // background-color: #f2f2f2;
        font-weight: bold;
        border-bottom: 0.5px solid #C5C5C5;
    }
    .tree-padding{
        padding: 0 10px;
    }
    .tree-padding p{
        padding: 3px 10px;
    }

    .react-flow__resize-control.handle{
        width: 8px;
        height: 8px;
    }
}


.static-react-flow {
    .react-flow__node-default {
        width: 300px;
        min-height: 100px;
        border: 0;
        text-align: left;
        border: 1px solid rgba(116, 120, 141, 0.3);
        box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
        padding: 0;
    }

    // .inner-box {
    //     display: flex;
    //     flex-direction: column;
    //     height: 100px;
    //     justify-content: space-between;
    // }

    // .inner-box .row-div {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    // }

    .inner-box .row-div {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        font-weight: bold;
        color: #74788D;
        font-size: 14px;
    }

    .inner-box .row-div:last-child {
        text-align: right;
    }

    .react-flow__node-default .tree-padding {
        padding: 4px 10px;
    }

    .inner-box .row-div span {
        display: block;
        color: #2F2F2F;
        font-size: 20px;
    }

    .inner-box>p:first-child {
        font-weight: bold;
        border-bottom: 0.5px solid #C5C5C5;
    }

    // @keyframes clippath {

    //     0%,
    //     100% {
    //         clip-path: inset(0 0 95% 0);
    //     }

    //     25% {
    //         clip-path: inset(0 95% 0 0);
    //     }

    //     50% {
    //         clip-path: inset(95% 0 0 0);
    //     }

    //     75% {
    //         clip-path: inset(0 0 0 95%);
    //     }
    // }

    // .animated-border {
    //     &::before {
    //         content: "";
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         right: 0;
    //         bottom: 0;
    //         border: 2px solid #002A59;
    //         transition: all .5s;
    //         animation: clippath 3s infinite linear;
    //     }
    // }
}

.tree-view-box {
    height: calc(100vh - 85px);
    width: 100%;
    position: relative;
}

.tax-round {
    background-color: white;
    color: black;
    // border: 1px solid black;
    // height: 20px;
    // width: 20px;
    // border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 12px;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
}

.edgeButton {
    cursor: pointer;
    pointer-events: all;
    fill: white;
  }
  
  .edgeButton:hover {
    fill: #f5f5f5;
  }
  
  .edgeButtonText {
    cursor: pointer;
  }
  
  .edgePath {
    fill: none;
    stroke: skyblue;
    cursor: pointer;
    stroke-width: 3;
  }
  
  .placeholderPath {
    stroke-width: 1;
    stroke-dasharray: 3 3;
    stroke: #bbb;
    fill: none;
  }

.aggregate input[type=color]{
	height: 26px;
	border: none;
	background: none;
}

.aggregate input[type="color"]::-webkit-color-swatch-wrapper {
    border: none;
    padding: 0;
    margin: 0;
}

.aggregate input[type="color"]::-webkit-color-swatch {
	border: none; 
	border-radius: 5px;
}
  



.react-flow__node-custom{
    background-color: white;
    border: 1px solid rgba(116, 120, 141, 0.3);
    box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
    border-radius: 10px;
    padding: 10px;
}
