.left-chevron,
.left-point {
  position: relative;
  width: 70px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e6ecf3; /* Just for visibility, you can set your desired background */
}

.left-chevron:before {
  content: '';
  position: absolute;
  right: -24px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 25px solid #e6ecf3;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
}
.left-point:before {
  content: '';
  position: absolute;
  left: -24px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-top: 30px solid #6a86a6;
  border-bottom: 30px solid #6a86a6;
}

.left-point {
  text-align: center;
  color: #fff;
  /* padding-left: 20px; */
  margin-left: 30px;
  background-color: #6a86a6; /* Just for visibility, you can set your desired background */
  /* clip-path: polygon(100% 0%, 100% 100%, 75% 100%, 0% 100%, 25% 50%, 0% 0%); */
}

.portfolio-statistics {
  color: var(--headerColor);
  font-family: 'Open Sans', sans-serif;
}
.portfolio-statistics p {
  margin: 0;
}

.portfolio-statistics .header {
  font-size: 10px;
  font-weight: 600;
}

.portfolio-statistics .paragraph {
  font-size: 8px;
  font-weight: 400;
}

.box-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-left: 5px;
  border-bottom: 1px solid #727474;
  border-top: 1px solid #727474;
}

.box-items .items-border {
  width: 1px;
  height: 80%;
  border-left: 0.5px solid #727474;
}
.box-items .items {
  max-width: 65px;
}
.box-items .items p {
  text-align: center;
}
