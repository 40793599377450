.strategy-portfolio {
    .file-upload-block {
        background: rgba(116, 120, 141, 0.05);
        border: 0.5px dashed rgba(116, 120, 141, 0.5);
        border-radius: 10px;
        margin-top: 0px;
        padding: 0 15px;
        height: 130px;
        width: 100%;

        p {
            margin-bottom: 0;
        }

        h5 {
            font-size: 16px !important;
        }

        .btn-soft-blue {
            background: rgba(23, 92, 194, 0.5);
            border-radius: 5px;
            border: none;
        }

        .btn-soft-red {
            background: #F05F5F26;
            border: none;
            color: #F05F5F;
            font-weight: bold;
        }

        input[type="file"] {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
        }

        .uploaded-file {
            background-color: white;
            border-radius: 5px;
            padding: 8px;
            margin: 15px 0;

            p {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #74788D;
            }

            button {
                font-size: 16px;
            }
        }
    }

    .tab-header {
        text-transform: capitalize !important;
        font-size: 16px !important;
        line-height: 21px;
        color: #34475A !important;
    }

    .card-layout {
        margin-top: 8px;
    }
}

.strategy-validation-popup{
    .invalid-entry{
        background: #f05f5f1a;
    }
    .warning-entry {
        background: #F9F4D8;
    }
    .modified-entry {
        background: #e5f6fd;
    }
}

.strategy-download-button button{
    padding-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #3a76d4
}


.strategy-message-area{
    font-size: 14px;
    .rsw-editor{
        border: 1px solid rgba(116, 120, 141, 0.3) !important;
        border-radius: 10px !important;
        min-height: 130px;
        max-width: 100%;
        overflow: auto;
        scrollbar-width: none;
        &:has(.rsw-ce:focus) {
                border-color: #3A76D4 !important;
        }
        ol,ul{
            padding-left: 1rem !important;
        }
        .rsw-toolbar{
            // background-color: rgba(116, 120, 141, 0.05);
            position: sticky;
            top: 0;
            z-index: 99;
        }
    }
}