.create-hading {
  padding-bottom: 12px;
}
.create-text {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.grid-card {
  margin: 30px auto;
  display: grid;
  grid-gap: 30px;
  grid-template-rows: repeat(1, minmax(150px, min-content));
  grid-template-columns: repeat(auto-fill, 100px);
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 150px;
}
.grid-card .item {
  font-size: 12px;
  border-radius: 10px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  text-align: center;
  cursor: pointer;
}
.grid-card .item:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
    rgb(58 118 212 / 63%) 0px 0px 0px 3px;
}
.grid-card .item.selected {
  /* font-size: 16px !important; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
    rgb(58 118 212 / 63%) 0px 0px 0px 3px;
}
.grid-card .item .item-hading {
  color: var(--primaryColor);
  font-weight: 600;
}
.grid-card .item .item-text {
  color: var(--primaryColor);
  font-weight: 400;
}

.create-text {
  margin: 0 8px;
}
.cl-checkbox {
  font-size: 14px;
  color: var(--primaryColor) !important;
}
/* ============================================== */

.common-btn[aria-expanded='true'] {
  background-color: var(--skyColor);
  color: white;
}
