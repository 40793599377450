.pdf-agg-marketvalue {
  border-collapse: collapse;
  width: 100%;
  border: 0;
  font-family: 'Open Sans', sans-serif;
	padding-top: '12px';
}

.pdf-agg-marketvalue .text-header {
  font-size: 6px !important;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0.5px;
  vertical-align: bottom;
  color: black !important;
  padding: 0;
  padding-inline: 2.5px;
}

.pdf-agg-marketvalue .table-body tr {
  text-align: right;
}

.pdf-agg-marketvalue td {
  padding-inline: 2.5px;
  color: #333333;
  font-size: 6.5px;
  line-height: 11px;
  font-weight: 400;
  padding-top: 1px;
  padding-bottom: 1px;
}

.pdf-agg-marketvalue .table-border {
  padding-top: 12px;
}

.pdf-agg-marketvalue .borderBottom {
  border-bottom: 0.5px solid #e9e9e9;
}

.pdf-agg-marketvalue .headingBorder {
  border-bottom: 0.5px solid #d0d0d0;
}