.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 240px;
	background: #002A59;
	padding: 0px 16px 16px;
	transition: width 0.5s ease;
	z-index: 99;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	&:hover {
		cursor: pointer;
	}

	.logo {
		display: block;
		// width: 215px;
		text-align: center;
	}

	.logo-tablet {
		top: 0;
		position: sticky;
		// background-color: #002A59;
		z-index: 1;
		padding: 16px 16px 16px 10px;
		display: none;

		img {
			height: 25px;
			width: 25px;
		}

		.logo-text {
			display: none;
			font-weight: 500;
			font-size: 24px;
			line-height: 29px;
			font-family: 'Montserrat', sans-serif;
		}
	}

	.menu-list {
		list-style: none;

		.menu-item {
			padding: 6px 10px 6px 0;
			white-space: nowrap;
			cursor: pointer;

			.sub-menu.collapsed {
				display: none;
				transition: all 0.6s ease;
			}

			.img {
				width: 10px;
			}

			.sub-menu.collapsed.show {
				display: block;
				transition: all 0.6s ease;
			}

			.menu-link {
				font-weight: 500 !important;
				font-size: 16px;
				line-height: 21px;
				// color: rgba(255, 255, 255, 0.5);
				white-space: nowrap;
				text-decoration: none;
			}

			.sub-menu {
				.menu-link.active {
					// span {
					// color: white;
					// }
				}
			}

			&:has(.sub-menu .menu-link.active) {
				div:not(.sub-menu) {
					.menu-link {
						// color: white !important;
					}
				}
			}
		}

		.sub-menu {
			margin-left: 6px !important;
			padding-bottom: 6px !important;

	
			.menu-item {
				padding: 8px;
				padding-left: 20px;

				&:first-child {
					margin-top: 0px;
				}

				&:last-child {
					padding-bottom: 0px;
				}
			}
		}

		.menu-link.active {
			// color: white !important;
		}

		.menu-item.active {
			span {
				// color: white !important;
			}
		}
	}
}
.arrow-icon{
	// color: white;
	position: absolute;
	right: -8px;
}

.menubtn {
	background: transparent;
	border: none;
}

.rotate-0 {
	transform: rotate(0deg);
}

.rotate-90degn {
	transform: rotate(-90deg);
}

.right-8n {
	right: -8px;
}

@media screen and (max-width:600px) {
	.sidebar {
		display: none;
		padding: 0 16px 16px 16px;

		.logo {
			opacity: 0;
		}

		.menu-list {
			padding-top: 0;
			// padding-top: 15px;
		}
	}

	.sidebar.open {
		display: block;
		transition: all 0.5s ease-in;
	}

	.main-content{
		padding-top: 65px;
	}
}

@media screen and (min-width: 1401px) and (max-width: 1719px) {

	body[data-legacy-view="true"]{
			.sidebar {
					top: 0;
					left: 0;
					bottom: 0;
					width: 50px;
					padding: 0 16px 16px 16px;
			
					.menu-list {
						padding-top: 0;
						// padding-top: 15px;
						// padding-left: 5px !important;
			
						.menu-item {
							padding: 6px 0;
							margin: auto;
			
							.menu-link {
								// display: none;
								visibility: hidden;
							}
			
							.arrow-icon {
								// display: none;
								visibility: hidden;
							}
						}
					}
			
					.logo {
						display: none;
					}
			
					.logo-tablet {
						display: flex;
						justify-content: center;
						align-items: center;
						top: 0;
						position: sticky;
						// background-color: #002A59;
						z-index: 1;
						padding: 16px 16px 16px 10px;
					}
			}
			
			.sidebar.open {
				width: 240px;
				padding: 0 16px 16px 16px;
		
				.menu-list {
					padding-left: 0 !important;
					.menu-item {
						padding: 6px 0;
						margin: auto;
		
						.menu-link {
							// display: inline-block;
							visibility: visible;
						}
		
						.arrow-icon {
							// display: inline-block;
							visibility: visible;
						}
					}
				}
		
				.sub-menu {
					.menu-item {
						padding-left: 25px !important;
					}
				}
		
				.logo-tablet {
					.logo-text {
						display: inline-block;
					}
				}
			}
		
			.logo {
				display: none;
			}
	
			.logo-tablet {
				display: flex;
				justify-content: center;
				align-items: center;
				top: 0;
				position: sticky;
				// background-color: #002A59;
				z-index: 1;
				padding: 16px 16px 16px 10px;
			}
	}

}

@media screen and (min-width:601px) and (max-width:1400px) {
	.sidebar {
		top: 0;
		left: 0;
		bottom: 0;
		width: 50px;
		background-color: #34475A;

		.menu-list {
			padding-top: 0;
			// padding-top: 15px;
			// padding-left: 5px !important;

			.menu-item {
				padding: 6px 0;
				margin: auto;

				.menu-link {
					// display: none;
					visibility: hidden;
				}

				.arrow-icon {
					// display: none;
					visibility: hidden;
				}
			}
		}

		.logo {
			display: none;
		}
		
		.logo-tablet {
			display: flex;
			justify-content: center;
			align-items: center;
			top: 0;
			position: sticky;
		}
	}

	.sidebar.open {
		width: 240px;
		padding: 0 16px 16px 16px !important;
		.menu-list {
			padding-left: 0;
			.menu-item {
				padding: 6px 0;
				margin: auto;

				.menu-link {
					// display: inline-block;
					visibility: visible;
				}

				.arrow-icon {
					// display: inline-block;
					visibility: visible;
				}
			}
		}

		.sub-menu {
			.menu-item {
				padding-left: 24px !important;
			}
		}

		.logo-tablet {
			display: flex;
			justify-content: center;
			align-items: center;
			top: 0;
			position: sticky;
			width: 220px;
			z-index: 1;
			padding: 16px 27px 16px 10px;

			.logo-text {
				display: inline-block;
			}
		}
	}

	.sidebar.open~.topbar {
		left: 240px;
	}
}

@media screen and (min-width:1720px) {
	.sidebar {
		left: calc((100% - 1720px) / 2);
	}

	body[data-legacy-view="true"]{
			.sidebar {
					top: 0;
					left: 0;
					bottom: 0;
					width: 50px;
					padding: 0 16px 16px 16px;
			
					.menu-list {
						padding-top: 0;
						// padding-top: 15px;
						// padding-left: 5px !important;
			
						.menu-item {
							padding: 6px 0;
							margin: auto;
			
							.menu-link {
								// display: none;
								visibility: hidden;
							}
			
							.arrow-icon {
								// display: none;
								visibility: hidden;
							}
						}
					}
			
					.logo {
						display: none;
					}
			
					.logo-tablet {
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			
				.sidebar.open {
					width: 240px;
					padding: 0 16px 16px 16px;
			
					.menu-list {
						padding-left: 0 !important;
						.menu-item {
							padding: 6px 0;
							margin: auto;
			
							.menu-link {
								// display: inline-block;
								visibility: visible;
							}
			
							.arrow-icon {
								// display: inline-block;
								visibility: visible;
							}
						}
					}
			
					.sub-menu {
						.menu-item {
							padding-left: 24px !important;
						}
					}
			
					.logo-tablet {
						.logo-text {
							display: inline-block;
						}
					}
				}
			
				.sidebar.open~.topbar {
					left: 240px;
				}
		
				.logo-tablet {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		
			.sidebar.open {
				width: 240px;
		
				.menu-list {
					.menu-item {
						padding: 6px 0;
						margin: auto;
		
						.menu-link {
							// display: inline-block;
							visibility: visible;
						}
		
						.arrow-icon {
							// display: inline-block;
							visibility: visible;
						}
					}
				}
		
				.sub-menu {
					.menu-item {
						padding-left: 24px !important;
					}
				}
		
				.logo-tablet {
					.logo-text {
						display: inline-block;
					}
				}
			}
		
			.sidebar.open~.topbar {
				left: 240px;
			}
}