.btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    font-family: 'Open Sans';
    border-radius: 36px;
    padding: 6px 35px;

    &.btn-primary {
        color: #FFFFFF;
        background: var(--aris-blue);

    }
}

.btnRemove {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    border-radius: 36px;
    padding: 5px 18px;

    &.btn-primary {
        color: #FFFFFF;
        background: var(--aris-blue);

    }
}

.btnUploadRemove{
    position: relative;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    border-radius: 36px;
    padding: 5px 18px;
    transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  border-radius: 4px;
//   overflow: hidden;
  border: 2px solid var(--aris-blue);
  text-decoration: none;


    &.btnUploadRemove-primary {
        color: #FFFFFF;
        background: var(--aris-blue);
    }
}

.btnUploadRemove-progress {
    width: 200px;
    color: transparent;
    border-radius: 36px;
  }
  
  .btnUploadRemove-fill:after {
    content: '';
    background: var(--aris-blue);
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: 100%;
    transform: scaleX(0);
    transform-origin: 0;
    display: block;
    animation: fill 3.2s linear forwards;
    border-radius: 36px;
  }
  
  /* Button Complete */
  .btnUploadRemove-complete {
    padding: 5px 18px;
    color: #fff;
    pointer-events: none;
    border-radius: 36px;
  }
  
  .btnUploadRemove-complete:after {
    // font-family: FontAwesome;
    content: url('../images/uploaded.png');
    color: #fff;
    height: 100%;
    padding-left: 3px;
    position: absolute;
    top: 0; left: 0; right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--aris-blue);
    border-radius: 36px;
  }
  
  /* Animation */
  @keyframes fill {
    from { transform: scaleX(0); }
    to { transform: scaleX(1); }
  }