.table-container {
  font-family: 'Open Sans', sans-serif;
  color: black;
  border: 0;
}

.table-container th {
  font-size: 6px !important;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0.5px;
  vertical-align: bottom;
  color: black !important;
  padding: 0;
  padding-inline: 2.5px;
}

.table-container td {
  padding-inline: 2.5px;
  color: #333333;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 6.5px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
}

.table-container .borderBottom {
  border-bottom: 0.5px solid #e9e9e9;
}

.table-container .headingBorder {
  border-bottom: 0.5px solid #d0d0d0;
}

.table-container .w70 {
  width: 70%;
}

.table-container .w30 {
  width: 30%;
}

.table-container .table-border {
  padding-top: 12px;
}
