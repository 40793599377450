/* dropdownStyles.css */

.faq-accordion {
  font-size: 12px;
}

.faq-accordion-item {
  margin-bottom: 5px;
}

.faq-accordion-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  font-weight: 500;
  color: var(--primaryColor);
  cursor: pointer;
}

.faq-accordion-item.active .faq-accordion-title {
  color: var(--black);
}

.faq-accordion-content {
  max-height: 0;
  /* display: none; */
  overflow: hidden;
  padding: 0px;
}
.faq-accordion-content.open {
  /* display: block; */
  max-height: max-content;
  padding: 0 10px;
}

.faq-accordion-sub-ans {
  margin: 0;
  padding: 0;
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
}
.drag-group {
  margin-top: 5px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 6px;
  position: relative;
  margin-bottom: 12px;
}
.draggable-item {
  cursor: grab;
  opacity: 0.8;
}

/* .dragging {
  opacity: 0.3;
} */
/*============================*/
.faq-accordion-sub-que {
  display: inline-block;
}
.faq-accordion-sub-que {
  height: 20px;
  width: 26px;
  border-radius: 3px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
  border: 1px #727474 solid;
  margin-right: 7px !important;
  color: var(--headerColor);
  font-size: 10px;
  line-height: 1.8;
}
.faq-accordion-title {
  padding-top: 15px;
}
.faq-accordion-title span {
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  width: 80%;
}

.faq-accordion-title svg {
  color: var(--primaryColor) !important;
  height: 20px;
}
.horizontal-vertical-box .vertical .text-set-mid,
.horizontal-vertical-box .horizontal .text-set-mid {
  position: relative;
}
.horizontal-vertical-box .vertical svg {
  width: 5px;
  height: 30px;
}
.horizontal-vertical-box .horizontal svg {
  width: 30px;
  height: 5px;
}

/* /////////////////////////////////// */
.horizontal-vertical-box {
  position: relative;
  margin-right: 7px;
}
.horizontal-vertical-box .box {
  width: 25px;
  height: 25px;
  border-radius: 3px;
  border: 1px solid #727474;
  display: flex;
  align-items: center;
  justify-content: center;
}
.horizontal-vertical-box .horizontal {
  display: flex;
  position: absolute;
  bottom: 0;
  left: -6px;
  transform: translate(10%, 74%);
}

.horizontal-vertical-box .horizontal p {
  margin: 0;
  line-height: 0;
  font-size: 8px;
  position: absolute;
  top: 59%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.horizontal-vertical-box .vertical {
  position: absolute;
  left: -8px;
}

.horizontal-vertical-box .vertical p {
  margin: 0;
  line-height: 0;
  font-size: 8px;
  position: absolute;
  top: 45%;
  left: 2px;
  transform: translate(-50%, -50%);
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
