:root {
  --primaryColor: #727474;
  --transition: 0.8s;
  --white: #ffffff;
  --skyColor: #3a76d4;
  --black: #000000;
  --headerColor: #2f2f2f;
  --color-load: #2d2b9b57;
}

.show-reset-button {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.black_blur {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background: #0a0a0a80;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(5px);
}
.pdf-loader {
  --color: #2d2b9b57;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  --dim: 35px;
  width: var(--dim);
  height: var(--dim);
  position: relative;
  animation: spin988 2s linear infinite;
    color: var(--color-load);
}

.pdf-loader .circle {
  --color: currentColor;
  --dim: 3rem;
  width: var(--dim);
  height: var(--dim);
  background-color: var(--color);
  border-radius: 50%;
  position: absolute;
}
.main-background table {
  caption-side: bottom;
  border-collapse: collapse;
}

.flexOrAlign {
  display: flex;
  align-items: center;
}
.flexOrAlignOrBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.directionColumn {
  flex-direction: column;
}
.flexOrAlignStartOrBetWeen {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.evenly {
  justify-content: space-evenly;
}
.AlignStart {
  align-items: flex-start;
}
.around {
  justify-content: space-around;
}
.flexOrBetWeen {
  display: flex;
  justify-content: space-between;
}
.showInCenter {
  justify-content: center;
}
.showInRow {
  display: flex;
}
.padding-left-4 {
  padding-left: 1.5rem !important;
}
.padding-bottom-1 {
  padding-bottom: 0.25rem !important;
}
.pdf-loader .circle:nth-child(1) {
  top: 0;
  left: 0;
}

.pdf-loader .circle:nth-child(2) {
  top: 0;
  right: 0;
}

.pdf-loader .circle:nth-child(3) {
  bottom: 0;
  left: 0;
}

.pdf-loader .circle:nth-child(4) {
  bottom: 0;
  right: 0;
}
.main-background input {
  font-family: 'Open Sans';
}
.main-background textarea {
  font-family: 'Open Sans';
}
.upload-image-box {
  width: 100%;
  height: 100%;
  background: #0a0a0a80;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  opacity: 0.5;
  background: repeating-linear-gradient(
    -45deg,
    #727474,
    #727474 1px,
    #ffffff 1px,
    #ffffff 9px
  );
}

.upload-image {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
}
@keyframes spin988 {
  0% {
    transform: scale(1) rotate(0);
    /* color: #120eee34; */
  }

  20%,
  25% {
    transform: scale(1.3) rotate(90deg);
    /* color: #302bb43b; */
  }

  45%,
  50% {
    transform: scale(1) rotate(180deg);
    /* color: #241fc23b; */
  }

  70%,
  75% {
    transform: scale(1.3) rotate(270deg);
    /* color: #140de438; */
  }

  95%,
  100% {
    transform: scale(1) rotate(360deg);
    /* color: #302bb434; */
  }
}

.inline-block {
  display: inline-block !important;
}

.static {
  position: static !important;
}

.relative {
  position: relative !important;
}

.text-align-start {
  text-align: left !important;
}

.text-align-end {
  text-align: right !important;
}

.text-align-center {
  text-align: center !important;
}

.margin-0 {
  margin: 0 !important;
}

.margin-x-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.margin-x-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.margin-y-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.margin-y-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.margin-top-1 {
  margin-top: 0.25rem !important;
}

.margin-top-2 {
  margin-top: 0.5rem !important;
}

.margin-top-3 {
  margin-top: 1rem !important;
}

.margin-top-4 {
  margin-top: 1.5rem !important;
}

.margin-right-4 {
  margin-right: 1.5rem !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-1 {
  margin-bottom: 0.25rem !important;
}

.margin-bottom-2 {
  margin-bottom: 0.5rem !important;
}

.margin-bottom-3 {
  margin-bottom: 1rem !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-1 {
  padding: 0.25rem !important;
}

.padding-3 {
  padding: 1rem !important;
}

.padding-right-left-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.padding-right-left-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.padding-top-1 {
  padding-top: 0.25rem !important;
}

.padding-right-3 {
  padding-right: 1rem !important;
}

.width-auto {
  width: auto !important;
}


.flex-container {
  display: flex;
}

.content {
  background: var(--primaryColor);
  height: 300px;
  padding: 20px;
  margin-bottom: 20px;
}

.show-heder {
  /* padding: 76px 30px 33px 30px; */
  width: 100%;
  background: var(--white);
}

.main-container {
  border-radius: 10px;
  width: 100%;
  height: 100vhz;
}

.page-content {
  background: #EBECF0;
  /* background: #ebecf0; */
  overflow: auto;
  position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.main-background {
  height: calc(100vh - 83px);
  width: 100%;
  overflow: hidden;
  padding-bottom: 5px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.sub-background {
  /* height: calc(100vh - 60px); */
  /* width: calc(100% - 60px); */
  height: 100%;
  margin: 0px 3px;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
}

.sub-background ::-webkit-scrollbar{
  width: 6px;
  height: 6px;
}

.top-header {
  background-color: var(--white);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.main-contain {
  height: 100%;
  background-color: var(--white);
  width: 100%;
  margin-top: 17px;
  box-shadow: 0px 1px 8px 0px #3c3c3c26;
  display: flex;
  flex-grow: 1;
  overflow: auto;
  border-radius: 10px;
}

.common-btn {
  border: 1px solid #959595;
  cursor: pointer;
  border-radius: 5px;
  padding: 7px 18px;
  font-size: 12px;
  font-weight: 600;
  color: var(--primaryColor);
  background: transparent;
}

.save-dropdown .common-btn.show,
.common-btn.active {
  color: var(--white);
  background-color: var(--skyColor);
  border: 0;
}

.common-btn.active:disabled {
  color: var(--white);
  background-color: var(--primaryColor);
  border: 0;
  opacity: 0.5;
}

.save-dropdown .dropdown-menu {
  padding: 7px;
}

.save-dropdown .dropdown-menu li,
.save-dropdown .dropdown-menu .dropdown-item {
  font-size: 12px;
  font-weight: 500;
  color: var(--black);
}

.save-dropdown .dropdown-menu li:hover {
  background-color: #002a59;
  border-radius: 2px;
}

.templates-header {
  color: var(--headerColor);
  font-size: 16px;
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.pdf-margin {
  /* margin-top: 7px; */
  padding-top: 7px;
}

/*===============================*/
.report_edit_input {
  border-bottom: 1px #ddd solid !important;
  border: none;
  width: 100%;
  /*min-width: 190px;*/
}

.report_edit_input:focus {
  outline: none;
}

.pdf-upload-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  color:'#000';
}

/*===============================*/
.common-text-data {
  color: var(--primaryColor);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.common-text-header {
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 0;
}

.common-text-paragraph {
  font-size: 8px;
  font-weight: 400;
  margin-bottom: 0;
}

.line-clamp-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.progress-bar__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar__value {
  color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  gap: 8px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -15%);
}

.progress-bar__wrapper progress {
  width: 100%;
  padding: 0;
  /* margin: 0; */
  border-radius: 50px;
}

.progress-bar__wrapper progress[value]::-webkit-progress-bar {
  width: 100%;
  height: 10px;
  border-radius: 50px;
  background-color: var(--color-load);
  transition: width 300ms ease;
}

.progress-bar__wrapper progress[value]::-webkit-progress-value {
  width: 0;
  border-radius: 50px;
  background-color: #0c0cb39c;
  transition: width 300ms ease;
}

.pdf-upload-page svg {
  width: calc(100% - 5px) !important;
  height: calc(100% - 5px) !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    margin: 0;
  }

  @media print {

    /* Adjustments for printing */
    .page {
      box-shadow: none;
      margin: 0;
      padding: 0;

      -webkit-print-color-adjust: exact;
    }
  }
}

.svg-container svg{
  width: 100%;
  height: 100%;
}

.dropChild .apexcharts-gridline {
  stroke: #e9e9e9;
  stroke-width: 0.4px;
}

/* .dropChild .apexcharts-canvas .apexcharts-grid-borders .apexcharts-gridline:last-child {
    stroke: #8d8d8d;
} */