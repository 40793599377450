$step: 1%;

@for $i from 1 through 100 {
  .width-#{$i} {
    width: round($i * $step) !important;
  }
}
@for $i from 1 through 100 {
  .height-#{$i} {
    height: round($i * $step) !important;
  }
}

.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
// .text-center {
//   text-align: center !important;
// }
