.process-monitoring-page {
    /* padding-left: 280px; */
    /* padding-right: 43px; */
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    position: relative;
    margin-top: 0px !important;
}

@media screen and (max-width: 600px) {
    .process-monitoring-page {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (min-width: 601px) and (max-width: 1400px) {
    .process-monitoring-page {
        /* padding-left: 80px;
        padding-right: 40px; */
    }
}