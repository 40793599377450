.error-card{
  padding-left: "";
  padding-right: "";
  padding-top: "";
  padding-bottom: "";
  position: "";
}
.error-main-card{
  /* padding-left: 280px; */
  /* padding-right: 43px; */
  /* padding-top: 70px; */
  /* padding-bottom: 20px; */
  position: relative;
  
}
.error-title {
  max-width: 529px; 
  font-family: 'Open Sans';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: center; */
  color: #4b4b62;
  /* margin-bottom: 16px; */
}

.error-subtitle {
  max-width: 568px; 
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  /* text-align: center; */
  color: #4b4b62;
  margin-bottom: 24px;
}