.drag-area .header {
  font-weight: 600;
}

.drag-area .button {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.drag-area.active {
  border: 2px solid rgb(58 118 212 / 63%);
}
.drag-area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modal_body_padding:focus-visible {
  outline: unset;
}
