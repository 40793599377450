.import-portfolio {
    position: relative;
    .tab-header {
        text-transform: capitalize !important;
        font-size: 16px !important;
        line-height: 21px;
        color: #34475A !important;
    }
}

input::-webkit-file-upload-button:hover {
    cursor: pointer;
}

.import-portfolio {
    .file-upload-block {
        background: rgba(116, 120, 141, 0.05);
        border: 0.5px dashed rgba(116, 120, 141, 0.5);
        border-radius: 5px;
        padding: 0 15px;
        height: 160px;
        width: 60%;

        p {
            margin-bottom: 0;
        }

        h5 {
            font-size: 16px !important;
        }

        .btn-soft-blue {
            background: rgba(23, 92, 194, 0.5);
            border-radius: 5px;
            border: none;
        }

        .btn-soft-red {
            background: #F05F5F26;
            border: none;
            color: #F05F5F;
            font-weight: bold;
        }

        input[type="file"] {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
        }

        .uploaded-file {
            background-color: white;
            border-radius: 5px;
            padding: 8px;
            margin: 15px 0;
            margin-left: 30px;

            p {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #74788D;
            }

            button {
                font-size: 16px;
            }
        }
    }
}

.import-portfolio-download-button button{
    // display: flex;
    // align-items: center;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #3a76d4
}

.form-label,
.form-check-label {
    font-weight: 500;
    font-size: 16px;
    color: #74788D;
    margin-left: 0;
}

.table-head {
    color: #74788D !important;
}

.table-data {
    color: #34475A !important;
}

.input-group-text {
    background-color: transparent !important;
    padding-right: 0;

    ~.form-control {
        border-left: none;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

.input-group {
    &.invalid {
        .input-group-text {
            background: rgba(240, 95, 95, 0.1) !important;
            border-color: #F05F5F;
            border-right: 0;
        }

        .form-control {
            margin-left: 0px !important;
            background: rgba(240, 95, 95, 0.1) !important;
            border-color: #F05F5F;
            border-left: none !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }

        .invalid-feedback {
            display: block;
        }

        .react-datepicker-wrapper {
            input {
                background: rgba(240, 95, 95, 0.1) !important;
                border-color: #F05F5F !important;
                border-left: none !important;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }
        }
    }

    &:has(.react-datepicker-wrapper) {

        .input-group-text {
            border-right: 0 !important;
        }

        .react-datepicker-wrapper {
            flex-basis: 90%;
            width: 100%;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;

            input {
                width: 100%;
                box-shadow: none !important;
                border: 1px solid #ced4da;
                border-radius: 5px !important;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                border-left: 0 !important;
                font-size: 16px;
                color: #94A3B8;
                padding: 8px;
                margin-left: 1px;

                &:focus,
                &:focus-visible {
                    border: 1px solid #ced4da;
                    border-radius: 5px !important;
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                    border-left: 0 !important;
                    outline: none;
                }
            }
        }
    }
}

.request-btn {
    padding-top: 15px;
    padding-right: 43px;
    float: right;
}

.request-btn>button {
    background-color: #175CC2;
    color: white;
    border: none;
    width: 145px;
    height: 36px;
    border-radius: 36px;
}

.usernote {
    font-size: small;
}

@media screen and (min-width:1400px) {}

@media screen and (max-width:600px) {
    .import-portfolio {
        // padding-left: 20px;
        // padding-right: 20px;
    }
}

@media screen and (min-width:601px) and (max-width:1400px) {
    .import-portfolio {
        // padding-left: 80px;
        // padding-right: 40px;
    }
}

@media screen and (min-width:601px) and (max-width:800px) {}