.onboarding-page {
    // padding-left: 280px;
    // padding-right: 43px;
    // padding-top: 0px;
    // padding-bottom: 20px;
    position: relative;


    .portfolio-tab {
        .file-upload-block {
            background: rgba(116, 120, 141, 0.05);
            border: 0.5px dashed rgba(116, 120, 141, 0.5);
            border-radius: 5px;
            padding: 0 15px;
            height: 160px;
            width: 800px;

            p {
                margin-bottom: 0;
            }

            h5 {
                font-size: 16px !important;
                font-family: "Open Sans";
                font-weight: 600;
            }

            .btn-soft-blue {
                background: rgba(23, 92, 194, 0.5);
                border-radius: 5px;
                border: none;
            }

            .btn-soft-red {
                background: #F05F5F26;
                border: none;
                color: #F05F5F;
                font-weight: bold;
            }

            input[type="file"] {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
            }

            .uploaded-file {
                background-color: white;
                border-radius: 5px;
                padding: 8px;
                margin: 8px 0;
                height: 110px;
                margin-left: 30px;
                font-family: "Open Sans";

                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #74788D;
                }

                button {
                    font-size: 12px !important;
                }
            }
        }
    }

    .form-label,
    .form-check-label {
        font-weight: 600;
        font-size: 16px;
        // color: #464F5E;
        font-family: 'Open Sans';
        margin-left: 0;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.87);
    }

    .form-control,
    .form-select {
        box-shadow: none !important;
        border: 1px solid #ced4da;
        border-radius: 5px !important;
        font-size: 16px;
        // color: #94A3B8;
        padding-top: 8px;
        padding-bottom: 8px;

        &::placeholder {
            color: #74788D;
            // color: #94A3B8;
        }

        &.invalid {
            background: rgba(240, 95, 95, 0.1);
            border-color: #F05F5F;

            ~.invalid-feedback {
                display: block;
            }
        }
    }

    .react-datepicker-wrapper,
    .rbt {
        &.invalid {
            .form-control {
                background: rgba(240, 95, 95, 0.1);
                border-color: #F05F5F;
            }

            ~.invalid-feedback {
                display: block;
            }
        }
    }

    .input-group-text {
        background-color: transparent !important;
        padding-right: 0;

        ~.form-control {
            border-left: none;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }

    .input-group {
        &.invalid {
            .input-group-text {
                background: rgba(240, 95, 95, 0.1) !important;
                border-color: #F05F5F;
                border-right: 0;
            }

            .form-control {
                margin-left: 0px !important;
                background: rgba(240, 95, 95, 0.1) !important;
                border-color: #F05F5F;
                border-left: none !important;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }

            .invalid-feedback {
                display: block;
            }

            .react-datepicker-wrapper {
                input {
                    background: rgba(240, 95, 95, 0.1) !important;
                    border-color: #F05F5F !important;
                    border-left: none !important;
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                }
            }
        }

        &:has(.react-datepicker-wrapper) {

            // flex-wrap: nowrap !important;
            .input-group-text {
                border-right: 0 !important;
            }

            .react-datepicker-wrapper {
                flex-basis: 90%;
                width: 100%;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;

                input {
                    width: 100%;
                    box-shadow: none !important;
                    border: 1px solid #ced4da;
                    border-radius: 5px !important;
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                    border-left: 0 !important;
                    font-size: 16px;
                    color: #94A3B8;
                    padding: 8px;
                    margin-left: 1px;

                    &:focus,
                    &:focus-visible {
                        border: 1px solid #ced4da;
                        border-radius: 5px !important;
                        border-top-left-radius: 0 !important;
                        border-bottom-left-radius: 0 !important;
                        border-left: 0 !important;
                        outline: none;
                    }
                }
            }
        }
    }

    .progress-onboarding {

        //     .timeline {
        //         list-style-type: none;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //     }
        //     .li {
        //         transition: all 200ms ease-in;
        //     }
        //     .timestamp {
        //         margin-bottom: 10px;
        //         padding: 0px 40px;
        //         display: flex;
        //         flex-direction: column;
        //         align-items: center;
        //         font-weight: 700;
        //         font-size: 12px;
        //         text-align: center;
        //         color: #74788D;
        //     }
        //     .status {
        //         padding: 0px 40px;
        //         display: flex;
        //         justify-content: center;
        //         border-top: 20px solid  rgba(100, 100, 111, 0.1);
        //         position: relative;
        //         transition: all 200ms ease-in;
        //     }
        //     .status h4 {
        //         font-weight: 600;
        //     }
        //     .status:before {
        //         content: "";
        //         width: 14px;
        //         height: 14px;
        //         background-color: #D9D9D9;
        //         border-radius: 25px;
        //         position: absolute;
        //         top: -17px;
        //         left: 42%;
        //         transition: all 200ms ease-in;
        //     }
        //     .li.complete .status {
        //         border-top: 20px solid #175CC2;
        //     }
        //     .li.complete .status:before {
        //         background-color: white;
        //         border-style: solid;
        //         border-width: 4px;
        //         border-color: #D9D9D9;
        //         // border-image: linear-gradient(270deg, #175CC2 2.37%, rgba(255, 255, 255, 0) 100%);
        //         transition: all 200ms ease-in;
        //     }
        //     .li.complete .status h4 {
        //         color: #66DC71;
        //     }
        //     .li{
        //         &:last-child{
        //             .status{
        //             border-top-right-radius: 50px;
        //             border-bottom-right-radius: 50px;
        //             width: 50%;
        //             &::before{
        //                 left: auto !important;
        //                 right: 5px;
        //             }
        //             }
        //         }
        //         &:first-child{
        //             .status{
        //             border-top-left-radius: 50px;
        //             border-bottom-left-radius: 50px;
        //             width: 50%;
        //             float: right !important;
        //             &::before{
        //                 left: 5px !important;
        //             }
        //             }
        //         }
        //     }
        //     @media (min-device-width: 320px) and (max-device-width: 700px) {
        //         .timeline {
        //             list-style-type: none;
        //             display: block;
        //         }
        //         .li {
        //             transition: all 200ms ease-in;
        //             display: flex;
        //             width: inherit;
        //         }
        //         .timestamp {
        //             width: 100px;
        //         }
        //         .status:before {
        //             left: -8%;
        //             top: 30%;
        //             transition: all 200ms ease-in;
        //         }
        //     }
        // }
        .timeline {
            list-style-type: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .li {
            transition: all 200ms ease-in;
        }

        .timestamp {
            margin-bottom: 10px;
            padding: 0px 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: 700;
            font-size: 14px;
            text-align: center;
            color: #74788D;
            white-space: nowrap;
        }

        .status {
            padding: 0px 40px;
            display: flex;
            justify-content: center;
            border-top: 2px solid #D6DCE0;
            position: relative;
            transition: all 200ms ease-in;
        }

        .status h4 {
            font-weight: 600;
        }

        .status:before {
            content: "";
            width: 18px;
            height: 18px;
            background-color: white;
            border-radius: 25px;
            border: 1px solid #ddd;
            position: absolute;
            top: -10px;
            left: 42%;
            transition: all 200ms ease-in;
        }

        .li.complete .status,
        .li.current .status {
            border-top: 2px solid #175CC2;
        }

        .li.current .status:before {
            background-color: #175CC2;
            border: none;
            transition: all 200ms ease-in;
        }

        .li.complete .status:before {
            background-color: #169D6C;
            content: "✔";
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            color: white;
            border: none;
            transition: all 200ms ease-in;
        }

        .li.complete .status h4 {
            color: #175CC2;
        }

        @media (min-device-width: 320px) and (max-device-width: 700px) {
            .timeline {
                list-style-type: none;
                display: block;
            }

            .li {
                transition: all 200ms ease-in;
                display: flex;
                width: inherit;
            }

            .timestamp {
                width: 100px;
            }

            .status:before {
                left: -8%;
                top: 30%;
                transition: all 200ms ease-in;
            }
        }
    }

    .mh-500 {
        max-height: 450px;
        height: 100%;
        overflow-y: auto;
    }
}

.invalid-entry {
    background: rgba(240, 95, 95, 0.1);
    border-color: #F05F5F;
}

@media screen and (max-width:600px) {
    .onboarding-page {
        // padding-left: 20px;
        // padding-right: 20px;
    }
}

@media screen and (max-width:800px) {
    .onboarding-page {
        // padding-left: 80px;
        // padding-right: 40px;
    }
}

@media screen and (min-width:1024px) and (max-width:1400px) {
    .onboarding-page {
        // padding-left: 80px;
        // padding-right: 40px;
    }
}

@media screen and (min-width:601px) and (max-width:1024px) {
    .onboarding-page {
        // padding-left: 80px;
        // padding-right: 40px;
    }
}