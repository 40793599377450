.pdf-logo {
  width: 69px;
  position: absolute;
  /* bottom: -293px;
    left: 20px; */
  bottom: 30px;
}

.cover-page2 {
  position: relative;
  padding-top: 192px;
}

.cover-page2 .siteframe {
  width: 259px;
  position: absolute;
  left: -15px;
  top: 28%;
}

.cover-page2 .fontSize-22 {
  font-size: 22px;
  font-weight: 500;
}

.cover-page2 .fontSize-10 {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 2px;
  font-family: 'Open Sans', sans-serif;
  color: #002859;
}
.cover-page2 .fontSize-8 {
  font-size: 8px;
  font-weight: 400;
  margin-bottom: 2px;
  font-family: 'Open Sans', sans-serif;
  color: #002859;
}
