.top-securities-table {
  border-collapse: collapse;
  padding-top: 5px;
  width: 100%;
  font-family: 'Open Sans', sans-serif;

}
.top-securities-table .text-vertical {
  font-size: 8px;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  text-orientation: mixed;
  margin: 0;
  text-align: center;
}
.top-securities-table .text-rotate {
  font-size: 8px;
  position: absolute;
  writing-mode: vertical-rl;
  transform: rotate(270deg);
  text-orientation: mixed;
  top: 40%;
  left: 20px;
  text-align: center;
}
.top-securities-table .text-header {
  color: #ffffff !important;
  background: #3369a6;
  font-size: 10px !important;
  border-bottom: 0.5px solid rgba(116, 120, 141, 0.3);
  padding: 2px 5px;
  line-height: unset;
}
.top-securities-table .table-body {
  border: 0.5px solid #adc3db;
  border-bottom: 0.5px solid #adc3db !important;
  border-top: none !important;
}

.top-securities-table .table-body tr {
  border: 0.5px solid #adc3db;
  border-bottom: 0.5px solid #adc3db !important;
  border-top: none !important;
}
.top-securities-table .table-border {
  border: 0.5px solid #adc3db;
  border-bottom: 0.5px solid #adc3db !important;
  border-top: none !important;
}

.show-icon-top-securities-table p {
  font-size: 8px;
  margin: 0px 3px;
}
.show-icon-top-securities-table {
  /* display: flex;
  align-items: center;
  justify-content: flex-start; */
}
