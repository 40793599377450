.activity-summary {
  padding-top: 12px;
}

.activity-summary th {
  font-family: 'Open Sans';
  font-size: 6px !important;
  font-weight: 500;
  line-height: 9px !important;
  letter-spacing: 0em;
  text-align: left;
  vertical-align: bottom;
  color: black !important;
  letter-spacing: 0.5px;
  padding: 0;
  padding-inline: 2.5px;
}

.activity-summary .headingBorder {
  /* background-image: linear-gradient(to bottom, #E9E9E9 , #E9E9E9 50%, #FFFFFF 50% 100%);
	background-position: 0 100%;
	background-repeat: no-repeat;
	background-size: 100% 1px; */
  border-bottom: 0.5px solid #dcddde;
}

.activity-summary .borderBottom {
  /* background-image: linear-gradient(to bottom, #E9E9E9 , #E9E9E9 50%, #FFFFFF 50% 100%);
	background-position: 0 100%;
	background-repeat: no-repeat;
	background-size: 100% 1px; */
  border-bottom: 0.5px solid #dcddde;
}

.activity-summary .total-row {
	border-bottom: 0.5px solid black;
}

.activity-summary .fontsize8 {
  font-family: 'Open Sans';
  font-size: 6.5px;
  line-height: 11px;
  font-weight: 400;
  text-align: left;
  color: #333333;
}

.activity-summary .text-header {
  font-size: 6px !important;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0.5px;
  vertical-align: bottom;
  color: black !important;
  padding: 0;
  padding-inline: 2.5px;
}