.check-box-spacing {
  margin-top: 20px;
}

.chart-label-container {
  margin-top: 80px;
  /* margin-bottom: 20px; */
  /*border-bottom: 1px transparent solid;*/
  border-bottom: 1px transparent solid;
}
.chart-label-container .margin-top-1 {
  margin-top: 0 !important;
}
.cl-checkbox {
  margin: 0px 0;
}
.color_picker_spacing {
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
}
