.book-of-business-page {
    // padding-left: 280px;
    // padding-right: 43px;
    // padding-top: 0px;
    // padding-bottom: 20px;
    position: relative;

    .feature-card {
        padding: 18px !important;
    }

    .assigned-models-container {
        margin-top: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .pie-chart {
            width: 200px;
            height: 200px;
        }

        .top-assigned-model-table-container {
            flex-basis: 60%;
            margin-top: 0;
        }

        .top-assigned-model-table {
            width: 100%;

            tbody {
                tr {
                    border-bottom: none;
                }
            }

            th,
            td {
                padding: 10px;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #74788D !important;
            }
        }
    }

    .tooltip-statWidget {
        .toolTipText {
            font-size: 12px;
            margin-top: 20px;
            visibility: hidden;
            width: auto;
            background-color: rgba(23, 92, 194, 0.8);
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px;
            text-transform: capitalize;
            /* Position the tooltip */
            position: absolute;
            z-index: 99999;
        }
    }

    .tooltip-statWidget:hover {
        .toolTipText {
            visibility: visible;
            text-transform: capitalize;
        }
    }

    .grid-card {
        .text-title {
            font-size: 14px !important;
            margin-bottom: 10px !important;
            margin-top: 20px;
            font-weight: 600;
        }

        .value {
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 0;
            color: #34475A;
        }
    }
}

.reset-btn {
    background: rgba(116, 120, 141, 0.1) !important;
    border-radius: 3px !important;
    font-size: 12px !important;
    color: #34475A !important;
    border: none !important;
    padding: 3px !important;
    text-transform: capitalize !important;
}

#chart-container .apexcharts-yaxistooltip-0::before,
#chart-container .apexcharts-yaxistooltip-0::after,
#chart-container .apexcharts-yaxistooltip-1::before,
#chart-container .apexcharts-yaxistooltip-1::after {
    display: none;
}

#chart-container .apexcharts-yaxistooltip-1 {
    border: none;
    background-color: #33A68D;
    color: white !important
}

.custom-tooltip::-webkit-scrollbar {
    width: 6px !important;
}

.custom-tooltip::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.custom-tooltip::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

div#date-row,
div#data-row {
    display: flex;
}

@media screen and (max-width:600px) {
    .book-of-business-page {

        // padding-left: 20px;
        // padding-right: 20px;
        .feature-card {
            padding: 18px !important;
        }

        .assigned-models-container {
            margin-top: 30px;

            .top-assigned-model-table-container {
                flex-basis: 100%;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width:800px) {
    .book-of-business-page {
        .assigned-models-container {
            .top-assigned-model-table-container {
                flex-basis: 100%;
                width: 100%;
                margin-top: 16px;
            }
        }
    }
}

@media screen and (min-width:1024px) and (max-width:1400px) {
    .book-of-business-page {

        // padding-left: 80px;
        // padding-right: 40px;
        .assigned-models-container {
            .pie-chart {
                width: 170px;
                height: 170px;
                margin-left: -30px;
            }
        }
    }
}

@media screen and (min-width:601px) and (max-width:1024px) {
    .book-of-business-page {

        // padding-left: 80px;
        // padding-right: 40px;
        .assigned-models-container {
            margin-top: 40px;

            .pie-chart {
                width: 250px;
                height: 250px;
            }
        }

        .feature-card {
            padding: 18px !important;
        }
    }
}