.tax-management-summary-box {
  color: var(--headerColor);
  font-family: 'Open Sans', sans-serif;
  background-color: #e6ecf3;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.tax-management-summary-box .width-90 {
  width: 90%;
}

.tax-management-summary-box p {
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  margin: 0;
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-container .circle1 {
  background-color: #517dbf7d;
  opacity: 0.5;
  border-radius: 100%;
  width: 85px;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffff;
  padding: 0 20.7px;
  word-break: break-all;
}

.circle-container .circle2 {
  background-color: #517dbf7d;
  opacity: 0.5;
  border-radius: 100%;
  width: 85px;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffff;
  padding: 0 20.7px;
  word-break: break-all;
  margin-left: -15px;
}

.tax-management-summary-box .header {
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
}

.tax-management-summary-box .paragraph {
  text-align: center;
  font-size: 8px;
  font-weight: 400;
}

.relative-container {
  position: relative;
}

.relative-container .absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffff;
  font-size: 10px;
  font-weight: 700;
}

.circle3 {
  background-color: #33a68dbd;
  border-radius: 100%;
  width: 85px;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffff;
  padding: 0 20.7px;
  word-break: break-all;
}
