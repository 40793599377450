.topbar {
    display: none;
    position: fixed;
    top: 0;
    left: 240px;
    right: 0;
    background-color: white;
    border-bottom: 1px solid #74788D33;
    height: 65px;
    padding-left: 40px;
    padding-right: 43px;
    z-index: 99;

    .logo-mobile {
        display: none;
    }

    .menu-toggle {
        display: none;
        margin: 20px 0;
    }

    .input {
        display: inline-flex;
        background: rgba(116, 120, 141, 0.1);
        box-shadow: 0px 4px 6px rgba(116, 120, 141, 0.06);
        border-radius: 25px;
        margin-right: 10px;
        padding: 8px 40px;
        width: 350px;

        input {
            background-color: transparent;
            outline: none;
            box-shadow: none;
            border: none;
            width: 100%;
        }
    }

    .pbbutton {
        display: inline-flex;
        margin-right: 10px;
    }

    .policy-check-btn {
        font-family: 'Open Sans' !important;
        font-size: 14px;
    }
}

.menu-toggle {
    display: none;
    margin: 20px 0;
}

.policybreakchek {
    width: 600px !important;
}

.css-12z3s3j {
    overflow: hidden;
}

.css-1nourpr-MuiTableContainer-root {
    // width: 100%;
    overflow: hidden !important;
    // height: 100vh;
}

// .MuiBox-root{
// }
.css-qruqq7 {
    border-radius: 13px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 20px;

}

@media screen and (max-width:600px) {
    .topbar {
        display: block;
        left: 0px;
        // padding-left: 16px;
        // padding-right: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo-mobile {
            display: inline-flex;
            align-items: center;
            margin-left: 26px;

            .logo-text {
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                font-family: 'Montserrat', sans-serif;
                color: #74788D;
            }
        }

        .input {
            display: flex;
            padding: 10px;
            width: 40px;

            input {
                display: none;
            }
        }

        .menu-toggle {
            display: inline-block;
        }
    }
         .menu-toggle {
             display: inline-block;
         }
}

@media screen and (min-width:601px) and (max-width:1400px) {
    .topbar {
        left: 60px;
        padding-right: 40px;
        transition: left 0.5s ease;
    }
}

@media screen and (min-width:601px) and (max-width:800px) {
    .topbar {
        .input {
            width: 250px;
            padding: 10px 25px;
        }
    }
}

@media screen and (min-width:1720px) {
    .topbar {
        left: calc((100% - 1240px) / 2);
        right: calc((100% - 1720px) / 2);
    }
}